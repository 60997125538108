/* eslint-disable no-console */
import {
    resolveInventory, resolveInventoryTrucks
} from '../../services/inventory';

export const START_LOADING_INVENTORY = 'START_LOADING_INVENTORY';
export const STOP_LOADING_INVENTORY = 'STOP_LOADING_INVENTORY';
export const LOAD_INVENTORY_DATA = 'LOAD_INVENTORY_DATA';

export default function fetchInventoryData(asOfDate=null) {
    return (dispatch, getState) => {
        const { isFirstTime, asOf } = getState().inventory;
        console.log('STATE as of:', asOf, 'NEW as of: ', asOfDate);
        if (isFirstTime || asOf !== asOfDate) {
            const promises = [
                resolveInventory(asOfDate),
                resolveInventoryTrucks(asOfDate)
            ];
            dispatch({ type: START_LOADING_INVENTORY });
            return Promise.all(promises)
                .then((results) => {
                    const [inventory, inventoryTrucks] = results;
                    dispatch({
                        type: LOAD_INVENTORY_DATA,
                        inventory,
                        inventoryTrucks,
                        asOfDate
                    });
                    dispatch({ type: STOP_LOADING_INVENTORY });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_INVENTORY });
                });
        }
        return null;
    };
}

