export default function resolveTruckDetailCharts(truckDetails) {
    console.log('BUILD TRUCK DETAIL CHARTS...', truckDetails);
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }
    let totalSales = truckDetails.items.reduce((pv, cv) => pv + (cv.sale_price * 1), 0);
    let totalRetail = truckDetails.items.reduce((pv, cv) => {
        if(!cv.retail_price) {
            return pv;
        }
        return pv + (cv.retail_price * 1);
    }, 0);
    let totalCost = (truckDetails.cost * 1) + (truckDetails.freight_cost * 1);
    
    let data = {
        items_scanned: truckDetails.items.length,
        items_sold: truckDetails.items.filter(it => (!!it.date_sold)).length,
        total_sales: '$' + totalSales.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        total_cost: '$' + totalCost.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        profit: '$' + (totalSales - totalCost).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        total_retail: '$' + totalRetail.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        potential_sales: 0,
        recovery_sales: 0,
        recovery_retail: 0,
        avg_recovery_rate: 'N/A',
        scans: {
            labels: [],
            value: [],
        },
        sales: {
            labels: [],
            auction: []
        },
        channelScans: {
            labels: ['AUCTION','POS/ONLINE', 'EBAY'],
            value: [0,0,0],
        },
        channelRecovery: {
            labels: ['POS', 'ONLINE', 'AUCTION'],
            value: [],
        },
        channels: {
            labels: ['POS', 'ONLINE', 'AUCTION', 'UNSOLD'],
            value: [0,0,0,0],
        },
    };
/*
    data.scans.labels = truckDetails.items.map(item => {
        let dt = new Date(item.date_created);
        return dt.toISOString().slice(0, 10);
    }).filter(unique);
    */

    truckDetails.items.forEach(item => {
        let dt = new Date(item.date_created).toISOString().slice(0, 10);
        if(data.scans.labels.indexOf(dt) < 0) {
            data.scans.labels.push(dt);
            data.scans.value.push(1); 
        } else {
            let idx = data.scans.labels.indexOf(dt);
            data.scans.value[idx]++;
        }

        let sIdx = (item.sales_channel_id * 1) - 1;
        data.channelScans.value[sIdx]++;

        if(item.date_sold) {
            dt = new Date(item.date_sold).toISOString().slice(0, 10);
            if(data.sales.labels.indexOf(dt) < 0) {
                data.sales.labels.push(dt);
                data.sales.auction.push(0);
            } 
            let idx = data.sales.labels.indexOf(dt);
            
            data.sales.auction[idx]++;

            idx = data.channels.labels.indexOf(item.sales_channel);
            data.channels.value[idx]++;
            //data.channelRecovery.value[idx] += item.sale_price * 1;

            if(item.retail_price) {
                data.recovery_retail += item.retail_price * 1;
                data.recovery_sales += item.sale_price * 1;
            }
        } else {
            let idx = data.channels.labels.indexOf('UNSOLD');
            data.channels.value[idx]++;
            data.potential_sales += (item.price * 1);
        }
    });
    data.scans.title = data.items_scanned + " Items Scanned";
    data.scans.badge = (truckDetails.date_closed) ? "CLOSED " + new Date(truckDetails.date_closed).toISOString().slice(0, 10) : 'OPEN';

    data.sales.title = data.items_sold + " Items Sold";
    data.sales.badge = ((data.items_sold / data.items_scanned) * 100).toFixed(1) + '%';
  
    data.channelRecovery.title = "Recovery Rate by Channel";
    data.channels.title = "Items Sold by Channel";

    data.channelScans.title = "Scans by Channel";

    data.potential_sales = '$' + data.potential_sales.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
    
    if(data.recovery_retail > 0) {
        data.avg_recovery_rate = ((data.recovery_sales / data.recovery_retail) * 100).toFixed(1) + '%';
    }


    const calcRecoverRate = (channelName, items) => {
        let filteredItems = items.filter(v => v.retail_price > 0).filter(v => v.sales_channel === channelName);
        if(filteredItems.length === 0) {
            return 0;
        }
        //console.log('FILTERED ITEMS', channelName, filteredItems);
        let retailValue = filteredItems.reduce((pv, cv) => pv + (cv.retail_price * 1), 0);
        let recoveryValue = filteredItems.reduce((pv, cv) => pv + (cv.sale_price * 1), 0);

        return ((recoveryValue / retailValue) * 100).toFixed(1);
    }


    data.channelRecovery.value.push(calcRecoverRate('POS', truckDetails.items));
    data.channelRecovery.value.push(calcRecoverRate('ONLINE', truckDetails.items));
    data.channelRecovery.value.push(calcRecoverRate('AUCTION', truckDetails.items));
    
    return data;
}
