

export default function resolveTrucks() {
    return fetch("https://recommerce-pist2.herokuapp.com/api/trucks", {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got trucks!!1', res);
          return res.json();
      });
}
