import React, { useEffect } from 'react';
import { Spinner } from 'react-rainbow-components';
import ScansBarChart from './ScansBarChart';
import useGlobal from '../../store';

const BidsSection = (props) => {

    const [recentBids, kpiActions] = useGlobal(
        state => state.recentBids,
        actions => actions.kpiActions
    );
    const [dailyBids] = useGlobal(
        state => state.dailyBids
    );

    useEffect(() => {
        kpiActions.getRecentBids(25);
        kpiActions.getDailyBids();
    }, []);

    const getDailyBidsDatasets = (data) => {
        return [
            {
                title: 'BIDS',
                color: '#0061a5',
                values: data.map(row => parseInt(row.total))
            }
        ];
    }

    const getHourlyBidsDatasets = (data) => {
        return [
            {
                title: 'BIDS',
                color: '#0061a5',
                values: data.map(row => parseInt(row.total))
            }
        ];
    }

    return (
        <div style={{padding:"20px"}}>
            <h1 style={{fontSize:"30px"}} className="react-rainbow-admin-header-pages_title">Bids</h1>
            <p style={{display:"flex",width:"50%", alignItems: "center", justifyContent:"space-between"}} >Bidding over the last 12 hours and 2 weeks. Bid data delayed by up to 15 minutes.</p>
            <div style={{display:"flex", margin:"10px 0px"}}>
            { recentBids ? <ScansBarChart title="Hourly Bids" badge={recentBids.reduce((pv,cv) => pv + parseInt(cv.total), 0).toLocaleString()} labels={recentBids.map(row => row.d.split(" ")[1])} datasets={getHourlyBidsDatasets(recentBids)}></ScansBarChart> : <Spinner></Spinner> }
            { dailyBids ? <ScansBarChart title="Daily Bids" badge={dailyBids.reduce((pv,cv) => pv + parseInt(cv.total), 0).toLocaleString()} labels={dailyBids.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])} datasets={getDailyBidsDatasets(dailyBids)}></ScansBarChart> : <Spinner></Spinner> }
            </div>
        </div>
    )
}

export default BidsSection;