import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Dataset from 'react-rainbow-components/components/Dataset';

export default class ChartsSection extends PureComponent {
    render() {
        const {
            chartsData,
        } = this.props;
        return (
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-users_card"
                    title="Total Trucks Received"
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Last 6 Months</h1>
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.received.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.received.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>
                <Card
                    className="react-rainbow-admin-users_card"
                    title="Average Cost / Truck"
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Last 6 Months</h1>
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.avgCost.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.avgCost.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>
                
            </section>
        );
    }
}

ChartsSection.propTypes = {
    chartsData: PropTypes.object.isRequired,
};
