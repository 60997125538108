import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import TablePagination from '../../../components/TablePagination';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import DateCell from '../../components/dateCell';
import { CSVLink } from "react-csv";


export default class ReturnsTabs extends Component {
    constructor(props) {
        console.log('RETURNS TABS PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'returns',
            activePage: 1,
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    getCsvData() {
        const { returns } = this.props;
        
        const csvData = [["returnID", "returnDate", "scanner", "authorizer", "itemID", "item", "reason", "customer", "invoiceID", "dateCancelled", "location", "price", "buyer_premium", "lot_fee", "sub_total", "tax", "buyer_assurance", "grand_total", "refund_total"]].concat(returns.map(i => {
            return [
                i.id,
                i.date_created,
                i.scanned_by,
                i.authorized_by,
                i.returned_item_id,
                i.product_name,
                i.reason,
                i.name,
                i.invoice_id,
                i.date_cancelled,
                i.location,
                i.price,
                i.buyer_premium,
                i.lot_fee,
                (i.price *1) + (i.lot_fee*1) + (i.buyer_premium *1),
                i.tax,
                i.buyer_assurance,
                i.total,
                (i.reason === "Buyers Assurance") ? (i.total * 1) - (i.buyer_assurance *1) : i.total
            ];
        }));
        return csvData;
        
    }
    getTableData() {
        const { returns } = this.props;
        const { activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 200;
        const lastItem = firstItem + 200;

        return returns.slice(firstItem, lastItem);
    }

    getPages() {
        const { returns } = this.props;
        const { activeTabName } = this.state;
        return Math.ceil(returns.length / 200);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="RETURNS"
                        name="returns" />
                    <Tab
                        label="SEARCH"
                        name="search" />

                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName !== 'search'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" sortable field="id" defaultWidth={100} />
                            <Column header="INVOICE" sortable field="invoice_id" defaultWidth={100} />
                            <Column header="DATE" field="date_created" component={DateCell} defaultWidth={100}/>  
                            <Column header="SCANNER" field="scanned_by" defaultWidth={130} />
                            <Column header="AUTHORIZER" field="authorized_by" defaultWidth={150}/>
                            <Column header="REASON" field="reason" defaultWidth={150} />
                            <Column header="ITEM" field="product_name"  />
                            <Column header="CUSTOMER" field="name" defaultWidth={100} />
                            <Column header="LOCATION" field="location" defaultWidth={100} />
                            <Column header="TOTAL" field="total" component={CurrencyCell} defaultWidth={100} />
                            <Column header="TAX" field="tax" component={CurrencyCell} defaultWidth={100} />
                            <Column header="CANCELLED" field="date_cancelled" component={DateCell} defaultWidth={100}/> 
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
       
                </div>
            </div>
        );
    }
}

ReturnsTabs.propTypes = {
    returns: PropTypes.array.isRequired,
};
