

export default function resolveTruckDetails(truckId=145) {
    console.log('FETCHING TRUCK DETAILS...', truckId);
    return fetch("https://recommerce-pist2.herokuapp.com/api/trucks/" + truckId, {
      headers: {
          'Authorization': process.env.REACT_APP_API_KEY
      }
  })
      .then(res => {
            return res.json();
      });
}
