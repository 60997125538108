const APIHOST = (process.env.NODE_ENV === "development") ? "http://localhost:9006/api" : "https://recommerce-pist2.herokuapp.com/api";


function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}

export function login(loginData) {
    console.log('LOGGING IN...');
    return fetch(APIHOST + "/userauth/admin", {
        method: 'POST', 
        body: JSON.stringify(loginData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}