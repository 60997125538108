/* eslint-disable no-console */
import {
    resolveSales
} from '../../services/sales';

export const START_LOADING_SALES = 'START_LOADING_SALES';
export const STOP_LOADING_SALES = 'STOP_LOADING_SALES';
export const LOAD_SALES_DATA = 'LOAD_SALES_DATA';


export default function fetchSalesData(startTime=null, endTime=null) {
    return (dispatch, getState) => {
        const { isFirstTime, start, end } = getState().sales;
        if (isFirstTime || start !== startTime || end !== endTime) {
            const promises = [
                resolveSales(startTime, endTime)
            ];
            dispatch({ type: START_LOADING_SALES });
            return Promise.all(promises)
                .then((results) => {
                    const [sales] = results;
                    dispatch({
                        type: LOAD_SALES_DATA,
                        sales,
                        startTime,
                        endTime
                    });
                    dispatch({ type: STOP_LOADING_SALES });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_SALES });
                });
        }
        return null;
    };
}

