export default function getClosedTrucks(trucks) {
    return trucks.filter(truck => truck.date_closed !== null).map(truck => {
        truck.id_obj = {
            short_id: truck.id,
            long_id: truck.long_id
        }
        truck.profit = (truck.total_sales - truck.cost).toFixed(2);
        truck.roi = (truck.profit / truck.cost * 100).toFixed(1);
        return truck;
    });
}
