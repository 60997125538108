import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchTrucksData from '../../../redux/actions/trucks';
import ContentPage from './contentPage';
import './styles.css';

class Trucks extends Component {
    componentDidMount() {
        const { fetchTrucksData } = this.props;
        fetchTrucksData();
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Trucks" />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title="Trucks"
                        description="" />
                </div>
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Trucks.propTypes = {
    fetchTrucksData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.trucks;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchTrucksData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Trucks);
