import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import TablePagination from '../../../components/TablePagination';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import DateCell from '../../components/dateCell';
import { CSVLink, CSVDownload } from "react-csv";


export default class InventoryTabs extends Component {
    constructor(props) {
        console.log('PROPS', props);
        super(props);
        this.state = {
            inventory: props.inventory,
            inventoryTrucks: props.inventoryTrucks,
            activeTabName: 'currentInventory',
            activePage: 1,
            sortedBy: undefined,
            sortDirection: 'asc',
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSort = this.handleOnSort.bind(this);
    }

    handleOnSort(event, field, nextSortDirection) {
        console.log('SORTING', field, nextSortDirection);
        const { inventory, sortedBy, sortDirection } = this.state;
        let newData = [...inventory];

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            return reverse * ((a > b) - (b > a));
        });
   
        this.setState({ inventory: sortedData, sortedBy: field, sortDirection: nextSortDirection });
    }

    getTableData() {
        const { inventory, activeTabName, activePage } = this.state;
        console.log('ACTIVE PAGE: ' + activePage);
        const firstItem = (activePage - 1) * 100;
        const lastItem = firstItem + 100;
        return inventory.slice(firstItem, lastItem);
    }

    getCsvData() {
        const { inventory } = this.props;
        const csvData = [["inventoryId", "dateScanned", "truckId", "productName", "status", "qty", "cost", "salesChannel", "retailPrice","location","auction", "lotNumber","palletId"]].concat(inventory.map(i => {
            return [
                i.id,
                i.date_created,
                i.truck_long_id,
                i.product_name.replace(/"/g, '&quot;'),
                i.status,
                i.quantity,
                i.cost,
                i.sales_channel_name,
                i.retail_price,
                i.location,
                i.auction_number,
                i.lot_number,
                i.pallet_id
            ];
        }));
        return csvData;
    }

    getPages() {
        const { inventory } = this.props;
        const { activeTabName } = this.state;
        return Math.ceil(inventory.length / 100);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    getTruckTableData() {
        const { inventoryTrucks } = this.state;
        return inventoryTrucks;
    }

    handleOnSortTrucks(event, field, nextSortDirection) {
        console.log('SORTING', field, nextSortDirection);
        const { inventoryTrucks, sortedBy, sortDirection } = this.state;
        let newData = [...inventoryTrucks];

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            return reverse * ((a > b) - (b > a));
        });
   
        this.setState({ inventory: sortedData, sortedBy: field, sortDirection: nextSortDirection });
    }

    render() {
        const { sortDirection, sortedBy, activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="CURRENT INVENTORY"
                        name="currentInventory" />
                    <Tab
                        label="TRUCKS"
                        name="trucks" />
                    <Tab
                        label="SEARCH"
                        name="search" />
                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName === 'currentInventory'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table 
                            data={this.getTableData()} 
                            onSort={this.handleOnSort}
                            sortDirection={sortDirection}
                            sortedBy={sortedBy}
                            keyField="id">
                            <Column header="ID" field="id" sortable defaultWidth={100} />
                            <Column header="DATE" field="date_created" component={DateCell} defaultWidth={100}/>
                            <Column header="TRUCK" field="truck_long_id" defaultWidth={100}/>
                            <Column header="NAME" field="product_name" />
                            <Column header="STATUS" field="status" defaultWidth={100} />
                            <Column header="LOCATION" field="location" defaultWidth={150} />
                            <Column header="COST" field="cost" component={CurrencyCell} sortable defaultWidth={100} />
                            <Column header="RETAIL" field="retail_price" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="CHANNEL" field="sales_channel_name" defaultWidth={100} />
                            <Column header="AUCTION" field="auction_number" defaultWidth={100} />
                            <Column header="LOT" field="lot_number" defaultWidth={100} />
                            <Column header="PALLET" field="pallet_id" defaultWidth={100} />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
                    <RenderIf isTrue={activeTabName === 'trucks'}>
                        <Table 
                            data={this.getTruckTableData()} 
                            onSort={this.handleOnSortTrucks}
                            sortDirection={sortDirection}
                            sortedBy={sortedBy}
                            keyField="id">
                            <Column header="ID" field="id" sortable defaultWidth={100} />
                            <Column header="TRUCK" field="long_id" defaultWidth={150}/>
                            <Column header="DATE RECEIVED" field="date_received" component={DateCell} defaultWidth={150}/>
                            <Column header="DATE CLOSED" field="date_closed" component={DateCell} defaultWidth={150}/>
                            <Column header="COST" field="cost" component={CurrencyCell} sortable defaultWidth={100} />
                            <Column header="FREIGHT" field="freight_cost" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="TOTAL SCANS" field="total_scans" defaultWidth={150} />
                            <Column header="PRE SCANS" field="scans_pre_date" defaultWidth={150} />
                            <Column header="POST SCANS" field="scans_post_date" defaultWidth={150} />
                            <Column header="PRE COST" field="cost_pre_date" component={CurrencyCell} sortable defaultWidth={150} />
                            <Column header="POST COST" field="cost_post_date" component={CurrencyCell} sortable defaultWidth={150} />
                        </Table>
                    </RenderIf>
                    <RenderIf isTrue={activeTabName === 'search'}>
                        <h2>Search Inventory</h2>
                    </RenderIf>
                </div>
            </div>
        );
    }
}

InventoryTabs.propTypes = {
    inventory: PropTypes.array.isRequired,
};
