import React, { useEffect, useState } from 'react';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import PercentCell from '../../components/percentCell';
import NumberCell from '../../components/numberCell';
import DateCell from '../../components/dateCell';

import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import useGlobal from '../../../store';
import AuctionIdCell from './auctionIdCell';
import { CSVLink } from "react-csv";


const AuctionsTabs = (props) => {
    
    const [tableData, setTableData] = useState([]);
    const [auctionTableData, setAuctionTableData] = useState([]);
    const [activeTab, setActiveTab] = useState('locations');
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedBy, setSortedBy] = useState();
    const [sortDirection2, setSortDirection2] = useState('asc');
    const [sortedBy2, setSortedBy2] = useState();

    const [auctionLocations] = useGlobal(
        state => state.auctionLocations
    );
    const [auctionData] = useGlobal(
        state => state.auctionData
    );

    useEffect(() => {
        if(!auctionLocations) {
            return;
        }
        setTableData(auctionLocations);
    }, [auctionLocations]);

    useEffect(() => {
        if(!auctionData) {
            return;
        }
        setAuctionTableData(auctionData);
    }, [auctionData]);


    const handleOnSort = (event, field, nextSortDirection) => {
        console.log('SORTING', event, field, nextSortDirection);
        
        let newData = [...auctionLocations];

        setSortDirection(nextSortDirection);
        setSortedBy(field);

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });


   
        setTableData(sortedData);
    }

    const handleOnSort2 = (event, field, nextSortDirection) => {
        console.log('SORTING2', event, field, nextSortDirection);
        
        let newData = [...auctionData];

        setSortDirection2(nextSortDirection);
        setSortedBy2(field);

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });


   
        setAuctionTableData(sortedData);
    }


    const getCsvData = () => {
        const csvData = [["Auction", "Location", "DateClosed", "Lots", "RetailValue", "Cost", "Revenue", "ProfitLoss", "Recovery", "InvoicedTotal"]].concat(auctionTableData.map(i => {
            return [
                i.auction_number,
                i.building_name,
                i.closing_date,
                i.total_lots,
                i.total_retail,
                i.total_cost,
                i.total_revenue,
                i.profit,
                i.recovery,
                i.total_invoiced
            ];
        }));
        return csvData;
    }

    return (<div>
        <Tabset
            onSelect={(evt, tab) => setActiveTab(tab) }
            className="react-rainbow-admin-users_tab-set"
            activeTabName={activeTab}>
            <Tab
                label="LOCATIONS"
                name="locations" />
            <Tab
                label="AUCTION LIST"
                name="auctions" />

        </Tabset>
        <div className="react-rainbow-admin-users_tab-content">
            <RenderIf isTrue={activeTab === 'locations'}>
                <Table data={tableData} 
                            onSort={handleOnSort}
                            sortDirection={sortDirection}
                            sortedBy={sortedBy} keyField="id">
                    <Column header="CODE" field="code" defaultWidth={80}/>
                    <Column header="NAME" field="name" />
                    <Column header="AUCTIONS" field="total_auctions" component={NumberCell} sortable={true} defaultWidth={120}/>
                    <Column header="LOTS" field="total_lots" component={NumberCell} sortable={true} defaultWidth={100}/>
                    <Column header="AVG LOTS" field="avg_lots" component={NumberCell} sortable={true} defaultWidth={115}/>
                    <Column header="AVG REVENUE" field="avg_revenue" component={CurrencyCell} sortable={true} defaultWidth={145}/>
                    <Column header="TOTAL REVENUE" field="total_revenue" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="TOTAL COGS" field="total_cost" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="ROI" field="roi" component={PercentCell} defaultWidth={115} />
                    <Column header="RECOVERY" field="recovery" component={PercentCell} defaultWidth={115} />
                    <Column header="BIDS / LOT" field="avg_bids_per_lot" component={NumberCell} sortable={true} defaultWidth={130}/>
                </Table>
     
            </RenderIf>
            <RenderIf isTrue={activeTab === 'auctions'}>
                <CSVLink className="btn btn-primary download-link" data={getCsvData()}>Download as CSV</CSVLink>
                <Table data={auctionTableData} onSort={handleOnSort2}
                            sortDirection={sortDirection2}
                            sortedBy={sortedBy2} keyField="id">
                    <Column header="ID" field="id_obj" component={AuctionIdCell} defaultWidth={140} />
                    <Column header="LOCATION" field="building_name" sortable={true} />
                    <Column header="CLOSED" component={DateCell} sortable={true} field="closing_date" />
                    <Column header="LOTS" field="total_lots" component={NumberCell}  />
                    <Column header="RETAIL" field="total_retail" component={CurrencyCell}  />
                    <Column header="COST" field="total_cost" component={CurrencyCell} sortable={true} />
                    <Column header="REVENUE" field="total_revenue" sortable={true} component={CurrencyCell} />
                    <Column header="P/L" field="profit" sortable={true} component={CurrencyCell} sortable={true} />
                    <Column header="ROI" field="roi" component={PercentCell} />
                    <Column header="RECOVERY" field="recovery" component={PercentCell} />
                    <Column header="INVOICED" field="total_invoiced" sortable={true} component={CurrencyCell} />
                </Table>
                
            </RenderIf>

        </div>
    </div>);

}
export default AuctionsTabs;