import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'react-rainbow-components/components/ButtonGroup';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import MenuItem from 'react-rainbow-components/components/MenuItem';
import ButtonMenu from 'react-rainbow-components/components/ButtonMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { BarsIcon } from '../icons';
import './styles.css';
import { Button } from 'react-rainbow-components';
import useGlobal from '../../store';
import { navigateTo } from '../../history';


const SectionHeading = ({ onToogleSidebar }) => {

    const [kpis, kpiActions] = useGlobal(
        state => state.kpis,
        actions => actions.kpiActions
    );

    const refreshKpis = (locationId) => {
        var date = new Date();
        
        const beginning = new Date(date.getFullYear(), date.getMonth(), 1);
        const ending = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        
        console.log('LOCATION: ', locationId);
        console.log('BEGINNING: ' , beginning);
        console.log('ENDING: ', ending);
        navigateTo('/location/' + locationId);
        kpiActions.setKpis(null);
        kpiActions.getKpis(formatDate(beginning), formatDate(ending), locationId);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    return (
        <header className="react-rainbow-admin_header rainbow-position_fixed rainbow-flex rainbow-align_center rainbow-p-horizontal_large rainbow-background-color_white">
            <img src="https://www.mac.bid/static/media/M@C.0caf7aa3.png" alt="logo" className="react-rainbow-admin_header-logo" />
            <section className="rainbow-flex rainbow-align_center react-rainbow-admin_header-actions">
                <ButtonGroup className="rainbow-m-right_medium">
                    <Button variant="neutral"><span>Select Location</span></Button>
                    <ButtonMenu menuAlignment="right" menuSize="xx-small" icon={<FontAwesomeIcon icon={faAngleDown} title="Location" />}>
                        <MenuItem label="Washington - A" onClick={() => refreshKpis(1)}/>
                        <MenuItem label="Washington - B" onClick={() => refreshKpis(8)}/>
                        <MenuItem label="Washington - C" onClick={() => refreshKpis(10)}/>
                        <MenuItem label="Butler" onClick={() => refreshKpis(6)}/>
                        <MenuItem label="McKees Rocks" onClick={() => refreshKpis(9)}/>
                        <MenuItem label="Beaver" onClick={() => refreshKpis(12)}/>
                    </ButtonMenu>
                </ButtonGroup>
                
                
            </section>
            <ButtonIcon
                onClick={onToogleSidebar}
                className="react-rainbow-admin_header-hamburger-button"
                size="large"
                icon={<BarsIcon />} />
        </header>
    );
}

SectionHeading.propTypes = {
    onToogleSidebar: PropTypes.func,
};

SectionHeading.defaultProps = {
    onToogleSidebar: () => {},
};

export default SectionHeading;