import React, { useEffect } from 'react';
import { Spinner } from 'react-rainbow-components';
import ScansBarChart from './ScansBarChart';
import useGlobal from '../../store';

const RevenueSection = (props) => {

    const [dailyRevenue, kpiActions] = useGlobal(
        state => state.dailyRevenue,
        actions => actions.kpiActions
    );
    const [dailyRetailValue] = useGlobal(
        state => state.dailyRetailValue
    );

    useEffect(() => {
        kpiActions.getDailyRevenue();
        kpiActions.getDailyRetailValue();
    }, []);

    const getDailyRevenueDatasets = (data) => {
        return [
            {
                title: 'REVENUE',
                color: '#0061a5',
                values: data.map(row => parseInt(row.total_sales))
            }
        ];
    }

    const getDailyRecoveryDatasets = (retailData, revData) => {
        return [
            {
                title: 'RECOVERY RATE',
                color: '#0061a5',
                values: retailData.map( (row, idx) => Math.round(parseInt(revData[idx].total_sales) / parseInt(row.total_retail) * 10000) / 100 )
            }
        ];
    }

    return (
        <div style={{padding:"20px"}}>
            <h1 style={{fontSize:"30px"}} className="react-rainbow-admin-header-pages_title">Revenue</h1>
            <p style={{display:"flex",width:"50%", alignItems: "center", justifyContent:"space-between"}} >Revenue (excluding taxes) and recovery rate over the last 30 days.</p>
            <div style={{display:"flex", margin:"10px 0px"}}>
            { dailyRevenue ? <ScansBarChart title="Daily Revenue" subtitle="Last 30 days" badge={'Total $' + dailyRevenue.reduce((pv,cv) => pv + parseInt(cv.total_sales), 0).toLocaleString()} labels={dailyRevenue.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])} datasets={getDailyRevenueDatasets(dailyRevenue)}></ScansBarChart> : <Spinner></Spinner> }
            { dailyRevenue && dailyRetailValue ? <ScansBarChart title="Daily Recovery Rate" subtitle="Last 30 days" badge={'Total Retail $' + dailyRetailValue.reduce((pv,cv) => pv + parseInt(cv.total_retail), 0).toLocaleString()} labels={dailyRetailValue.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])} datasets={getDailyRecoveryDatasets(dailyRetailValue, dailyRevenue)}></ScansBarChart> : <Spinner></Spinner> }
            </div>
        </div>
    )
}

export default RevenueSection;