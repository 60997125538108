import React, { useEffect, useState } from 'react';


const AuctionLotDashboard = (props) => {

    const [currentBid, setCurrentBid] = useState(props.current_bid);
    const [isFlashing, setIsFlashing] = useState(false);

    const flashUpdate = () => {
        setIsFlashing(true);
        setTimeout(() => {
            setIsFlashing(false);
        }, 2000);
    }

    useEffect(() => {
        if(props.current_bid === currentBid) {
            return;
        }
        console.log('NEW BID!!', props.current_bid);
        setCurrentBid(props.current_bid);
        flashUpdate();
    }, [props]);

    return (
        <div className={isFlashing ? "highlight" : ""} style={{display:"flex", borderBottom: "1px solid #EEE", padding: "5px 0px"}}>
            <div style={{width: "100px"}}>{props.lot_number}</div>
            <div style={{width: "350px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{props.title}</div>
            <div style={{width: "100px", textAlign:"center"}}>{props.is_open ? "OPEN" : "CLOSED"}</div>
            <div style={{width: "100px", textAlign:"center"}}>{props.total_bids}</div>
            <div style={{width: "100px", textAlign:"center"}}>{props.unique_bidders.length}</div>
            <div style={{width: "100px", textAlign:"right"}}>${props.current_bid}</div>
            <div style={{width: "100px", textAlign:"right"}}>${props.max_bid}</div>
        </div>
    )

}

export default AuctionLotDashboard;