import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../../components/PageHeader';
import { navigateTo } from '../../../../history';
import fetchAuctionDetailsData from '../../../../redux/actions/auctionDetails';
import ContentPage from './contentPage';
import './styles.css';

class AuctionDetail extends Component {
    auctionId = null;
    auctionNumber = '';
    componentDidMount() {
        const { fetchAuctionDetailsData } = this.props;
        console.log('AUCTION DETAIL', this.props);
        this.auctionId = this.props.match.params.id;
        this.auctionNumber = this.props.match.params.number;
        console.log(this.auctionNumber);
        fetchAuctionDetailsData(this.props.match.params.id);
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Auctions" onClick={() => navigateTo('/reports/auctions')} />
                        <Breadcrumb label={`Auction #${this.auctionNumber}`} />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title={`Auction #${this.auctionNumber} Details`}
                        description="" />
                </div>
                <ContentPage {...this.props} />
            </div>
        );
    }
}

AuctionDetail.propTypes = {
    fetchAuctionDetailsData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.auctionDetails;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAuctionDetailsData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(AuctionDetail);
