import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';

import TablePagination from '../../../components/TablePagination';

import RenderIf from 'react-rainbow-components/components/RenderIf';
import getSoldItems from './getSoldItems';
import getUnsoldItems from './getUnsoldItems';
import CurrencyCell from '../../components/currencyCell';
import PercentCell from '../../components/percentCell';
import { CSVLink, CSVDownload } from "react-csv";
import DateCell from '../trucks/dateCell';


export default class InventoryTabs extends Component {
    constructor(props) {
        console.log('PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'soldItems',
            activePage: 1,
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    getTableData() {
        const { items } = this.props;
       
        const soldItems = getSoldItems(items);
        const unsoldItems = getUnsoldItems(items);
        const { activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 50;
        const lastItem = firstItem + 50;
        if (activeTabName === 'soldItems') {
            return soldItems.slice(firstItem, lastItem);
        } 
        return unsoldItems.slice(firstItem, lastItem);
    }

    getCsvData() {
        const { items } = this.props;
        const { activeTabName, activePage } = this.state;
        
        let filteredItems = (activeTabName === 'soldItems') ? getSoldItems(items) : getUnsoldItems(items);
        
        const csvData = [["inventoryID", "dateScanned", "productName", "channel", "dateSold", "salePrice", "cost"]].concat(filteredItems.map(i => {
            return [
                i.id,
                i.date_created,
                i.product_name.replace(/"/g, '&quot;'),
                i.sales_channel || i.scanned_sales_channel,
                i.date_sold,
                i.sale_price,
                i.cost
            ];
        }));
        return csvData;
    }

    getPages() {
        const { items } = this.props;
        const soldItems = getSoldItems(items);
        const unsoldItems = getUnsoldItems(items);
        const { activeTabName } = this.state;
        if (activeTabName === 'soldItems') {
            return Math.ceil(soldItems.length / 50);
        }
        return Math.ceil(unsoldItems.length / 50);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="SOLD ITEMS"
                        name="soldItems" />

                    <Tab
                        label="UNSOLD ITEMS"
                        name="unsoldItems" />
                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName == 'soldItems'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" field="id" defaultWidth={100} />
                            <Column header="NAME" field="product_name" />
                            <Column header="SCANNED" field="date_created" component={DateCell} defaultWidth={100} />
                            <Column header="DATE SOLD" field="date_sold" defaultWidth={100}  />
                            <Column header="RETAIL" field="retail_price" component={CurrencyCell} defaultWidth={100} />                            
                            <Column header="SALE PRICE" field="sale_price" component={CurrencyCell} defaultWidth={100} />
                            <Column header="COST" field="cost" component={CurrencyCell} defaultWidth={100} />
                            <Column header="RECOVERY" field="recovery_rate" component={PercentCell} defaultWidth={100} />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
                    <RenderIf isTrue={activeTabName == 'unsoldItems'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table data={this.getTableData()} keyField="id">
                                <Column header="ID" field="id" defaultWidth={100} />
                                <Column header="NAME" field="product_name" />
                                <Column header="SCANNED" field="date_created" component={DateCell} defaultWidth={100} />
                                <Column header="RETAIL" field="retail_price" component={CurrencyCell} defaultWidth={100} />
                                <Column header="COST" field="cost" component={CurrencyCell} defaultWidth={100} />
                            </Table>
                            <TablePagination
                                pages={this.getPages()}
                                activePage={activePage}
                                onChange={this.handleOnChange} />
                    </RenderIf>
                </div>
            </div>
        );
    }
}

InventoryTabs.propTypes = {
    items: PropTypes.array.isRequired,
};
