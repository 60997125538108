import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';

import TablePagination from '../../../../components/TablePagination';
import CurrencyCell from '../../../components/currencyCell';
import PercentCell from '../../../components/percentCell';
import DateCell from '../../../components/dateCell';
import NumberCell from '../../../components/numberCell';
import YesNoCell from '../../../components/yesNoCell';

export default class AuctionDetailTabs extends Component {
    constructor(props) {
        console.log('AUCTION DETAIL PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'auctionLots',
            activePage: 1,
            lots: props.lots,
            sortedBy: undefined,
            sortDirection: 'asc'
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSort = this.handleOnSort.bind(this);
    }

    handleOnSort(event, field, nextSortDirection) {
        console.log('SORTING', field, nextSortDirection);
        const { lots, sortedBy, sortDirection } = this.state;
        let newData = [...lots];

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });
   
        this.setState({ lots: sortedData, sortedBy: field, sortDirection: nextSortDirection });
    }

    getTableData() {
        const { lots, activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 200;
        const lastItem = firstItem + 200;
        if (activeTabName === 'unsoldItems') {
            const unsoldItems = lots.filter(l => l.total_bids * 1 === 0);
            return unsoldItems.slice(firstItem, lastItem);
        }
        return lots.filter(l => l.total_bids * 1 > 0).slice(firstItem, lastItem);
    }

    getPages() {
        const { lots } = this.props;
        return Math.ceil(lots.length / 200);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { sortDirection, sortedBy, activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="AUCTION LOTS"
                        name="auctionLots" />
                    <Tab
                        label="UNSOLD LOTS"
                        name="unsoldItems" />
                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    
                    <Table data={this.getTableData()} onSort={this.handleOnSort}
                            sortDirection={sortDirection}
                            sortedBy={sortedBy} keyField="id">
                        <Column header="LOT" sortable field="lot_number" defaultWidth={90} />
                        <Column header="ITEM" field="product_name"  />
                        <Column header="INVENTORY" field="inventory_id" defaultWidth={130} />
                        <Column header="TOTAL BIDS" field="total_bids" defaultWidth={100} />
                        <Column header="WINNING BID" sortable field="winning_bid_amount" component={CurrencyCell} defaultWidth={130} />
                        <Column header="SALE PRICE" sortable field="sale_price" component={CurrencyCell} defaultWidth={130} />
                        <Column header="COST" field="cost" sortable component={CurrencyCell} defaultWidth={130} />
                        <Column header="P/L" field="profit" sortable component={CurrencyCell} defaultWidth={130} />
                        <Column header="RETAIL PRICE" sortable field="retail_price" component={CurrencyCell} defaultWidth={130} />
                    </Table>
                    <TablePagination
                        pages={this.getPages()}
                        activePage={activePage}
                        onChange={this.handleOnChange} />
            
                </div>
            </div>
        );
    }
}

AuctionDetailTabs.propTypes = {
    lots: PropTypes.array.isRequired,
};


