import React, { useEffect, useState } from 'react';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import PercentCell from '../../components/percentCell';
import NumberCell from '../../components/numberCell';
import DateCell from '../../components/dateCell';


import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import useGlobal from '../../../store';
import TruckIdCell from '../trucks/truckIdCell';
import { CSVLink } from 'react-csv';

const SourcesTabs = (props) => {
    
    const [tableData, setTableData] = useState([]);
    const [truckTableData, setTruckTableData] = useState([]);
    const [locationTableData, setLocationTableData] = useState([]);
    const [activeTab, setActiveTab] = useState('sources');
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedBy, setSortedBy] = useState();
    const [sortDirection2, setSortDirection2] = useState('asc');
    const [sortedBy2, setSortedBy2] = useState();
    const [sortDirection3, setSortDirection3] = useState('asc');
    const [sortedBy3, setSortedBy3] = useState();

    const [truckSources] = useGlobal(
        state => state.truckSources
    );
    const [truckData] = useGlobal(
        state => state.truckData
    );
    const [truckSourceLocations] = useGlobal(
        state => state.truckSourceLocations
    );

    useEffect(() => {
        if(!truckSources) {
            return;
        }
        setTableData(truckSources);
    }, [truckSources]);

    useEffect(() => {
        if(!truckData) {
            return;
        }
        setTruckTableData(truckData);
    }, [truckData]);

    useEffect(() => {
        if(!truckSourceLocations) {
            return;
        }
        setLocationTableData(truckSourceLocations);
    }, [truckSourceLocations])


    const handleOnSort = (event, field, nextSortDirection) => {
        console.log('SORTING', event, field, nextSortDirection);
        
        let newData = [...truckSources];

        setSortDirection(nextSortDirection);
        setSortedBy(field);

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });


   
        setTableData(sortedData);
    }

    const handleOnSort2 = (event, field, nextSortDirection) => {
        console.log('SORTING2', event, field, nextSortDirection);
        
        let newData = [...truckData];

        setSortDirection2(nextSortDirection);
        setSortedBy2(field);

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });


   
        setTruckTableData(sortedData);
    }

    const handleOnSort3 = (event, field, nextSortDirection) => {
        console.log('SORTING3', event, field, nextSortDirection);
        
        let newData = [...truckSourceLocations];

        setSortDirection3(nextSortDirection);
        setSortedBy3(field);

        const key = x => x[field];
        const reverse = nextSortDirection === 'asc' ? 1 : -1;

        const sortedData = newData.sort((a, b) => {
            a = key(a);
            b = key(b);
            if(isNaN(a)) {
                return reverse * ((a > b) - (b > a));
            }
            return reverse * (((a*1) > (b*1)) - ((b*1) > (a*1)));
        });

        setLocationTableData(sortedData);
    }

    const getSourceCsvData = () => {
        const csvData = [["Source ID", "Abbv", "Name", "TotalTrucks","AvgCost","AvgFreight","AvgRevenue","AvgProfit","ROI"]].concat(tableData.map(i => {
            return [
                i.id,
                i.abbreviation,
                i.name,
                i.total_trucks,
                i.avg_cost,
                i.avg_freight,
                i.avg_revenue,
                i.avg_profit,
                i.roi
            ];
        }));
        return csvData;
    }

    const getTruckCsvData = () => {
        const csvData = [["TruckID", "LongID", "Source", "LocationId", "DateReceived", "DateClosed", "DateCostCalc", "AuctionLots", "PalletLots","Retail","Cost","Freight","Lots Sold", "Sales"]].concat(truckTableData.map(i => {
            return [
                i.id,
                i.long_id,
                i.source_name,
                i.location_id,
                i.date_received,
                i.date_closed,
                i.date_cost_calc,
                i.auction_items,
                i.pallet_items,
                i.total_item_retail_value,
                i.cost,
                i.freight_cost,
                i.items_sold,
                i.total_sales
            ];
        }));
        return csvData;
    }
    return (<div>
        <Tabset
            onSelect={(evt, tab) => setActiveTab(tab) }
            className="react-rainbow-admin-users_tab-set"
            activeTabName={activeTab}>
            <Tab
                label="TRUCK SOURCES"
                name="sources" />
            <Tab
                label="TRUCK LIST"
                name="trucks" />
            <Tab
                label="LOCATION LIST"
                name="locations" />

        </Tabset>
        <div className="react-rainbow-admin-users_tab-content">
            <RenderIf isTrue={activeTab === 'sources'}>
                <CSVLink className="btn btn-primary download-link" data={getSourceCsvData()}>Download as CSV</CSVLink>
                <Table data={tableData} 
                            onSort={handleOnSort}
                            sortDirection={sortDirection}
                            sortedBy={sortedBy} keyField="id">
                    <Column header="ABBV" field="abbreviation" defaultWidth={100}/>
                    <Column header="NAME" field="name" defaultWidth={200} />
                    <Column header="TRUCKS" field="total_trucks" component={NumberCell} sortable={true} defaultWidth={80}/>
                    <Column header="LOTS" field="total_lots" component={NumberCell} sortable={true} defaultWidth={80}/>
                    <Column header="AVG COST" field="avg_cost" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="AVG FREIGHT" field="avg_freight" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="AVG REVENUE" field="avg_revenue" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="AVG P/L" field="avg_profit" component={CurrencyCell} sortable={true} defaultWidth={150}/>
                    <Column header="ROI" field="roi" component={PercentCell} sortable={true} defaultWidth={100}/>
                </Table>
     
            </RenderIf>
            <RenderIf isTrue={activeTab === 'trucks'}>
                <CSVLink className="btn btn-primary download-link" data={getTruckCsvData()}>Download as CSV</CSVLink>
                <Table data={truckTableData} onSort={handleOnSort2}
                            sortDirection={sortDirection2}
                            sortedBy={sortedBy2} keyField="id">
                    <Column header="ID" field="id_obj" component={TruckIdCell} defaultWidth={140} />
                    <Column header="SOURCE" field="source_name" sortable={true} />
                    <Column header="RECIEVED" component={DateCell} sortable={true} field="date_received" />
                    <Column header="CLOSED" component={DateCell} sortable={true} field="date_closed" />
                    <Column header="AUCTION LOTS" field="auction_items" component={NumberCell}  />
                    <Column header="PALLET LOTS" field="pallet_items" component={NumberCell}  />
                    <Column header="RETAIL" field="total_item_retail_value" component={CurrencyCell}  />
                    <Column header="COST" field="cost" component={CurrencyCell} sortable={true} />
                    <Column header="FREIGHT" field="freight_cost" component={CurrencyCell} sortable={true} />
                    <Column header="LOTS SOLD" field="items_sold" component={NumberCell}  />
                    <Column header="SALES" field="total_sales" sortable={true} component={CurrencyCell} />
                    <Column header="P/L" field="profit" sortable={true} component={CurrencyCell} />
                    <Column header="ROI" field="roi" component={PercentCell} />
                    <Column header="LOCATION" field="location_id" component={NumberCell} />
                </Table>
                
            </RenderIf>

            <RenderIf isTrue={activeTab === 'locations'}>
                <Table data={locationTableData} 
                            onSort={handleOnSort3}
                            sortDirection={sortDirection3}
                            sortedBy={sortedBy3} keyField="id">
                    <Column header="NAME" field="name" defaultWidth={200}/>
                    <Column header="TRUCKS" field="total_trucks" component={NumberCell} sortable={true} defaultWidth={80}/>
                    <Column header="LOTS" field="total_lots" component={NumberCell} sortable={true} defaultWidth={80}/>
                    <Column header="AVG COST" field="avg_cost" component={CurrencyCell} sortable={true} defaultWidth={200}/>
                    <Column header="AVG FREIGHT" field="avg_freight" component={CurrencyCell} sortable={true} defaultWidth={200}/>
                    <Column header="AVG REVENUE" field="avg_revenue" component={CurrencyCell} sortable={true} defaultWidth={200}/>
                    <Column header="AVG P/L" field="avg_profit" component={CurrencyCell} sortable={true} defaultWidth={200}/>
                    <Column header="ROI" field="roi" component={PercentCell} sortable={true} defaultWidth={100}/>
                </Table>

            </RenderIf>

        </div>
    </div>);

}
export default SourcesTabs;