import React from 'react';
import PropTypes from 'prop-types';

export default function Twitter(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>icon-twitter</title>
            <desc>Created with Sketch.</desc>
            <g id="icon-twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M19.9442107,8.04821894 C19.9569061,8.22587588 19.9569061,8.40357189 19.9569061,8.58122883 C19.9569061,14 15.832554,20.2436963 8.29443865,20.2436963 C5.97208583,20.2436963 3.81473365,19.5710764 2,18.403611 C2.32996223,18.441658 2.64719003,18.4543533 2.98984762,18.4543533 C4.90606604,18.4543533 6.6700573,17.8071633 8.07869562,16.7030965 C6.27665733,16.6650104 4.76649518,15.4848105 4.24618065,13.8604291 C4.50000977,13.8984762 4.75379982,13.9238669 5.0203243,13.9238669 C5.38833355,13.9238669 5.75638186,13.8730854 6.09900039,13.784296 C4.22082899,13.4035524 2.81215161,11.7538194 2.81215161,9.76142876 L2.81215161,9.71068637 C3.3578178,10.0152579 3.9923906,10.2056102 4.66493228,10.2309618 C3.56086547,9.49490428 2.83754233,8.23857124 2.83754233,6.81723757 C2.83754233,6.05582834 3.04055094,5.35785687 3.39590389,4.74871386 C5.41368521,7.23602827 8.44670487,8.86037055 11.8476947,9.03806655 C11.784257,8.73349505 11.7461709,8.41626725 11.7461709,8.09900039 C11.7461709,5.84008531 13.5735999,4 15.8451713,4 C17.0253712,4 18.0913519,4.49492381 18.8401048,5.29441912 C19.7664757,5.11676217 20.6547994,4.77410459 21.4416384,4.3045715 C21.1370279,5.2563721 20.4898379,6.05586741 19.6396002,6.56344751 C20.4644862,6.4746581 21.2639815,6.24618065 22,5.92895285 C21.4417166,6.74110446 20.743706,7.4644276 19.9442107,8.04821894 Z" id="twitter" fill="#FFFFFF" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Twitter.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Twitter.defaultProps = {
    className: undefined,
    style: undefined,
};
