const APIHOST = (process.env.NODE_ENV === "development1") ? "http://localhost:9006/api" : "https://recommerce-pist2.herokuapp.com/api";


function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}

export function getTruckSources(start=null, end=null, sourceId=null, buildingId=null, includeOpen=1) {
    console.log('GETTING KPIS...');
    let url = APIHOST + "/sources";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + buildingId + "/" + includeOpen;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getTruckSourceLocations(start=null, end=null, sourceId=null, buildingId=null, includeOpen=1) {
    console.log('GETTING KPIS...');
    let url = APIHOST + "/sourcelocations";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + buildingId + "/" + includeOpen;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getTruckSourceList() {
    let url = APIHOST + "/trucksources";
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
}

export function getTruckData(start=null, end=null, sourceId=null, buildingId=null, includeOpen=1) {
    console.log('GETTING TRUCK DATA...');
    let url = APIHOST + "/truckdata";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + buildingId + "/" + includeOpen;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}
