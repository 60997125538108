import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchCustomersData from '../../../redux/actions/customers';
import ContentPage from './contentPage';

class Customers extends Component {
    componentDidMount() {
        const { fetchCustomersData } = this.props;
        fetchCustomersData();
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Customers" />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title="Customers"
                        description="" />
                </div>
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Customers.propTypes = {
    fetchCustomersData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.customers;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomersData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Customers);
