/* eslint-disable no-console */
import {
    resolvePayments
} from '../../services/payments';

export const START_LOADING_PAYMENTS = 'START_LOADING_PAYMENTS';
export const STOP_LOADING_PAYMENTS = 'STOP_LOADING_PAYMENTS';
export const LOAD_PAYMENTS_DATA = 'LOAD_PAYMENTS_DATA';


export default function fetchPaymentsData(startTime=null, endTime=null) {
    return (dispatch, getState) => {
        const { isFirstTime, start, end } = getState().payments;
        if (isFirstTime || start !== startTime || end !== endTime) {
            const promises = [
                resolvePayments(startTime, endTime)
            ];
            dispatch({ type: START_LOADING_PAYMENTS });
            return Promise.all(promises)
                .then((results) => {
                    const [payments] = results;
                    dispatch({
                        type: LOAD_PAYMENTS_DATA,
                        payments,
                        startTime,
                        endTime
                    });
                    dispatch({ type: STOP_LOADING_PAYMENTS });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_PAYMENTS });
                });
        }
        return null;
    };
}

