import {
    STOP_LOADING_TRUCK_DETAILS,
    LOAD_TRUCK_DETAILS_DATA,
    START_LOADING_TRUCK_DETAILS,
} from '../../actions/truckDetails';

const initialState = {
    id: 0,
    items: [],
    chartsData: {
        scans: {
            labels: [],
            value: [],
        },
        sales: {
            labels: [],
            value: [],
        },
    },
    isLoading: true,
    isFirstTime: true,
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}

function loadData(state, action) {
    console.log('loading data.....', action);
    const newState = {
        ...state,
        chartsData: action.chartsData,
        items: action.truckDetails.items,
        id: action.truckDetails.id,
        isFirstTime: false,
    };
    console.log('load data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function truckDetails(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_TRUCK_DETAILS:
            return startLoading(state);
        case LOAD_TRUCK_DETAILS_DATA:
            return loadData(state, action);
        case STOP_LOADING_TRUCK_DETAILS:
            return stopLoading(state);
        default:
            return state;
    }
}
