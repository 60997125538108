import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';

import TablePagination from '../../../components/TablePagination';
import getOpenTrucks from './getOpenTrucks';
import getClosedTrucks from './getClosedTrucks';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import TruckIdCell from './truckIdCell';
import CurrencyCell from './currencyCell';
import PercentCell from './percentCell';
import DateCell from './dateCell';
import NumberCell from '../../components/numberCell';

export default class TrucksTabs extends Component {
    constructor(props) {
        console.log('PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'openTrucks',
            activePage: 1,
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    getTableData() {
        const { trucks } = this.props;
        const { sources } = this.props;
        const openTrucks = getOpenTrucks(trucks);
        const closedTrucks = getClosedTrucks(trucks);
        const { activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 40;
        const lastItem = firstItem + 40;
        if (activeTabName === 'closedTrucks') {
            return closedTrucks.slice(firstItem, lastItem);
        } else if(activeTabName === 'sources') {
            return sources;
        }
        return openTrucks.slice(firstItem, lastItem);
    }

    getPages() {
        const { trucks } = this.props;
        const openTrucks = getOpenTrucks(trucks);
        const closedTrucks = getClosedTrucks(trucks);
        const { activeTabName } = this.state;
        if (activeTabName === 'closedTrucks') {
            return Math.ceil(closedTrucks.length / 40);
        }
        return Math.ceil(openTrucks.length / 40);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="OPEN TRUCKS"
                        name="openTrucks" />

                    <Tab
                        label="CLOSED TRUCKS"
                        name="closedTrucks" />
                    <Tab
                        label="SOURCES"
                        name="sources" />
                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName === 'openTrucks'}>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" field="id_obj" component={TruckIdCell} defaultWidth={130} />
                            <Column header="RECIEVED" component={DateCell} field="date_received" />
                            <Column header="COST" field="cost" component={CurrencyCell} sortable={true} />
                            <Column header="AUCTION ITEMS" field="auction_items" component={NumberCell}  />
                            <Column header="PALLET ITEMS" field="pallet_items" component={NumberCell}  />
                            <Column header="RETAIL" field="total_item_retail_value" component={CurrencyCell}  />
                            <Column header="SOLD" field="items_sold" component={NumberCell}  />
                            <Column header="SALES" field="total_sales" component={CurrencyCell} />
                            <Column header="P/L" field="profit" component={CurrencyCell} sortable={true} />
                            <Column header="ROI" field="roi" component={PercentCell} />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
                    <RenderIf isTrue={activeTabName === 'closedTrucks'}>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" field="id_obj" component={TruckIdCell} defaultWidth={130} />
                            <Column header="RECIEVED" component={DateCell} field="date_received" />
                            <Column header="COST" field="cost" component={CurrencyCell} sortable={true} />
                            <Column header="RETAIL" field="total_item_retail_value" component={CurrencyCell}  />
                            <Column header="AUCTION ITEMS" field="auction_items" component={NumberCell}  />
                            <Column header="PALLET ITEMS" field="pallet_items" component={NumberCell}  />
                            
                            <Column header="SOLD" field="items_sold" component={NumberCell}  />
                            <Column header="SALES" field="total_sales" component={CurrencyCell} />
                            <Column header="P/L" field="profit" component={CurrencyCell} sortable={true} />
                            <Column header="ROI" field="roi" component={PercentCell} />
                            <Column header="CLOSED" field="date_closed" component={DateCell} />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
                    <RenderIf isTrue={activeTabName === 'sources'}>
                    <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" field="id" defaultWidth={100} />
                            <Column header="NAME" field="name" />
                            <Column header="DESCRIPTION" field="description" />
                            <Column header="ABBV." field="abbreviation" />
                            <Column header="TOTAL TRUCKS" field="total_trucks" />
                        </Table>
                    </RenderIf>
                </div>
            </div>
        );
    }
}

TrucksTabs.propTypes = {
    trucks: PropTypes.array.isRequired,
};
