import React from 'react';
import PropTypes from 'prop-types';

export default function FollowerIcon(props) {
    const {
        className,
        style,
    } = props;

    return (
        <svg className={className} style={style} width="64px" height="56px" viewBox="0 0 64 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="new-pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="admin-pages" transform="translate(-646.000000, -163.000000)" fill="#F7076A" fillRule="nonzero">
                    <g id="Group-7" transform="translate(577.000000, 141.000000)">
                        <g id="followers" transform="translate(69.000000, 22.000000)">
                            <path d="M32,56 C31.55925,56 31.1185,55.8467307 30.765,55.5403159 L30.683125,55.4692524 C27.9565,53.1064537 25.395375,50.9449603 22.91875,48.8545303 C16.272375,43.2450965 10.53275,38.4008949 6.543125,33.7012964 C2.079125,28.4429706 0,23.5373623 0,18.2629419 C0,13.4175023 1.658375,8.88528678 4.669375,5.5012292 C7.826125,1.95362657 12.20525,0 17,0 C23.7435,0 28.012875,3.98896376 30.406875,7.33538494 C31.028125,8.20374595 31.556375,9.07445924 32,9.89379388 C32.443625,9.07445924 32.972,8.20374595 33.593125,7.33538494 C35.987125,3.98908756 40.2565,1.75936016e-15 47,1.75936016e-15 C51.79475,1.75936016e-15 56.173875,1.95375038 59.3305,5.5012292 C62.341625,8.88528678 64,13.4175023 64,18.2629419 C64,23.5373623 61.920875,28.4430944 57.456875,33.7012964 C53.46725,38.4008949 47.727625,43.2450965 41.081625,48.8542827 C38.604625,50.9449603 36.0435,53.1064537 33.316875,55.4692524 L33.235,55.5403159 C32.8815,55.8468545 32.44075,56 32,56 Z M17,3.71411896 C13.297375,3.71411896 9.9175,5.22093702 7.48275,7.95700465 C5.075625,10.6621213 3.75,14.3222617 3.75,18.2629419 C3.75,27.7967139 12.343125,35.049274 25.3505,46.027343 C27.485875,47.8295573 29.68325,49.6841407 32,51.6787464 C34.316875,49.6840169 36.514125,47.8295573 38.649125,46.0275906 C51.656875,35.049274 60.25,27.7967139 60.25,18.2629419 C60.25,14.3222617 58.924375,10.6621213 56.517125,7.95688085 C54.0825,5.22093702 50.702625,3.71411896 47,3.71411896 C41.858875,3.71411896 38.5355,6.85106383 36.653,9.48264092 C34.964375,11.8429635 34.08325,14.2221043 33.78275,15.13553 C33.531375,15.9000195 32.81175,16.4173962 32,16.4173962 C31.18825,16.4173962 30.468625,15.9000195 30.21725,15.13553 C29.91675,14.2222281 29.035625,11.8429635 27.347,9.48276472 C25.4645,6.85106383 22.141125,3.71411896 17,3.71411896 Z" id="Shape" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

FollowerIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

FollowerIcon.defaultProps = {
    className: undefined,
    style: undefined,
};
