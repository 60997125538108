/* eslint-disable no-console */
import {
    resolveAuctions,
    resolveAuctionCharts
} from '../../services/auctions';

export const START_LOADING_AUCTION = 'START_LOADING_AUCTION';
export const STOP_LOADING_AUCTION = 'STOP_LOADING_AUCTION';
export const LOAD_AUCTION_DATA = 'LOAD_AUCTION_DATA';


export default function fetchAuctionData(startTime=null, endTime=null) {
    return (dispatch, getState) => {
        const { isFirstTime, start, end } = getState().sales;
        if (isFirstTime || start !== startTime || end !== endTime) {
            const promises = [
                resolveAuctions(startTime, endTime)
            ];
            dispatch({ type: START_LOADING_AUCTION });
            return Promise.all(promises)
                .then((results) => {
                    const [auctions] = results;
                    let chartsData = resolveAuctionCharts(auctions);
                    dispatch({
                        type: LOAD_AUCTION_DATA,
                        auctions,
                        chartsData,
                        startTime,
                        endTime
                    });
                    dispatch({ type: STOP_LOADING_AUCTION });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_AUCTION });
                });
        }
        return null;
    };
}

