import * as apiClient from '../../services/api-client';


export const getAuctionLocations = async (store, start=null, end=null, sourceId=0, includeSpecials=true, includePallets=true) => {
    let auctionLocations = await apiClient.auctions.getAuctionLocations(start, end, sourceId, includeSpecials, includePallets);
    auctionLocations = auctionLocations.map(ts => {
        ts.avg_revenue = (ts.total_revenue / ts.total_auctions).toFixed(2);
        ts.roi = (((ts.total_revenue - ts.total_cost) / ts.total_cost) * 100).toFixed(2);
        ts.recovery = ((ts.total_revenue / ts.total_retail) * 100).toFixed(2)
        ts.avg_cost = (ts.total_cost / ts.total_auctions).toFixed(2);
        ts.avg_lots = (ts.total_lots / ts.total_auctions).toFixed(2);
        ts.avg_bids_per_lot = ((ts.total_bids) / (ts.total_lots)).toFixed(2);
        return ts;
    })
    console.log('GOT AUCTION LOCATIONS', auctionLocations);
    store.setState({ auctionLocations });
};

export const getAuctionData = async (store, start=null, end=null, sourceId=0, includeSpecials=true, includePallets=true) => {
    let auctionData = await apiClient.auctions.getAuctionData(start, end, sourceId, includeSpecials, includePallets);
    auctionData = auctionData.map(ts => {
        ts.id_obj = {
            id: ts.id,
            auction_number: ts.auction_number
        }
        ts.profit = (ts.total_cost * 1 > 0) ? (ts.total_revenue - ts.total_cost).toFixed(2) : "0.00";
        ts.roi = (ts.total_cost * 1 > 0) ? (((ts.total_revenue - ts.total_cost) / ts.total_cost) * 100).toFixed(2) : "0.00";
        ts.recovery = (ts.total_retail * 1 > 0) ? ((ts.total_revenue / ts.total_retail) * 100).toFixed(2) : "0.00";
        return ts;
    })
    store.setState({ auctionData });
};

export const getRetailBreakdownData = async (store, start=null, end=null, sourceId=0, includeSpecials=true, includePallets=true) => {
    let retailBreakdownData = await apiClient.auctions.getRetailBreakdownData(start, end, sourceId, includeSpecials, includePallets);
    store.setState({ retailBreakdownData });
};

