import React, { } from 'react';
import AuctionDashboard from './AuctionDashboard';
import AuctionDashboardStat from './AuctionDashboardStat';


const LiveAuctions = (props) => {

    const auctions = props.lots
                            .map((v) => v.auction)
                            .filter((value, index, self) => self.indexOf(value) === index);
    
    const activeUsers = 0;
    const totalBidValue = props.lots.reduce((pv,cv) => pv + cv.current_bid, 0);
    const totalBids = props.lots.reduce((pv,cv) => pv + cv.total_bids, 0);
    const totalOpenLots = props.lots.filter(l => l.is_open).length;

    return (
    <div style={{padding: "20px 30px"}}>
        <div style={{padding:"20px"}}>
            <h1 className="react-rainbow-admin-header-pages_title">All Live Auctions</h1>
            <p className="react-rainbow-admin-header-pages_description">Total Live Auctions: {auctions.length}</p>
            <div style={{display:"flex", margin:"10px 0px"}}>
                <AuctionDashboardStat stat="Active Users" value={activeUsers}></AuctionDashboardStat>
                <AuctionDashboardStat stat="Lots" value={props.lots.length} compare={totalOpenLots + ' open'}></AuctionDashboardStat>
                <AuctionDashboardStat stat="Bids" value={totalBids}></AuctionDashboardStat>
                <AuctionDashboardStat stat="Bid Value" value={'$' + totalBidValue}></AuctionDashboardStat>
                
            </div>

        </div>
        { auctions ? 
            auctions.map(auctionId => <AuctionDashboard key={auctionId} auction={auctionId} lots={props.lots.filter(l => l.auction === auctionId)}></AuctionDashboard>)
            : <></>
        }
    </div>
    )

}

export default LiveAuctions;