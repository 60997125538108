import {
    STOP_LOADING_INVENTORY,
    LOAD_INVENTORY_DATA,
    START_LOADING_INVENTORY,
} from '../../actions/inventory';

const initialState = {
    inventory: [],
    summary: {
        total_items: 0,
        total_retail_value: 0,
        total_cost: 0
    },
    chartsData: {
        channelCount: {
            title: 'Inventory Count by Location',
            labels: ['One'],
            value: [0],
        },
        channelValue: {
            title: 'Inventory Value by Location',
            labels: ['AUCTION', 'POS/ONLINE', 'EBAY','NONE'],
            value: [0,0,0,0],
        }
    },
    isLoading: true,
    isFirstTime: true,
    asOf: null
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}
function getSummaryData(data) {
    let summary = {
        total_items: 0,
        total_retail_value: 0,
        total_cost: 0
    };
    summary.total_items = data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    summary.total_retail_value = '$' + data.reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    summary.total_cost = '$' + data.reduce((pv, cv) => pv + (cv.cost * 1), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return summary;
}
function getChartData(data) {
    let chart = initialState.chartsData;
    let locations = data.map(it => it.location).filter((l, i, a) => a.indexOf(l) === i); //get unique list of locations
    chart.channelCount.labels = locations;

    for(let i=0; i<locations.length; i++) {
        chart.channelCount.value[i] = data.filter(it => (it.location===locations[i])).length;
    }

    chart.channelValue.labels = locations;
    for(let i=0; i<locations.length; i++) {
        chart.channelValue.value[i] = data.filter(it => (it.location===locations[i])).reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2) * 1;
    }
 
    return chart;
}
function loadData(state, action) {
    let summaryData = getSummaryData(action.inventory);
    let chartData = getChartData(action.inventory);
    const newState = {
        ...state,
        inventory: action.inventory,
        inventoryTrucks: action.inventoryTrucks,
        summary: summaryData,
        chartsData: chartData,
        isFirstTime: false,
        asOf: action.asOfDate,
    };
    console.log('load data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function trucks(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_INVENTORY:
            return startLoading(state);
        case LOAD_INVENTORY_DATA:
            return loadData(state, action);
        case STOP_LOADING_INVENTORY:
            return stopLoading(state);
        default:
            return state;
    }
}
