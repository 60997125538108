
export default function resolvePayments(start=null, end=null) {
    let url = "https://recommerce-pist2.herokuapp.com/api/payments";
    url += (start) ? "/" + start : "";
    url += (end) ? "/" + end : "";
    return fetch(url, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got payments!!', res);
          return res.json();
      });
}