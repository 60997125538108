import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchTruckDetailsData from '../../../redux/actions/truckDetails';
import ContentPage from './contentPage';
import './styles.css';

class TruckDetail extends Component {
    truckId = null;

    componentDidMount() {
        const { fetchTruckDetailsData } = this.props;
        console.log('TRUCK DETAIL', this.props);
        this.truckId = this.props.match.params.id;
        this.truckLongId = this.props.match.params.long;
        fetchTruckDetailsData(this.props.match.params.id);
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Trucks" onClick={() => navigateTo('/reports/trucksources')} />
                        <Breadcrumb label={`Truck #${this.truckLongId}`} />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title={`Truck #${this.truckLongId} Details`}
                        description="" />
                </div>
                <ContentPage {...this.props} />
            </div>
        );
    }
}

TruckDetail.propTypes = {
    fetchTruckDetailsData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.truckDetails;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchTruckDetailsData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(TruckDetail);
