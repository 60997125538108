import dashboard from './dashboard';
import charts from './charts';
import followers from './followers';
import users from './users';
import orders from './orders';
import app from './app';
import userDetails from './userDetails';

import trucks from './trucks';
import truckDetails from './truckDetails';
import inventory from './inventory';
import sales from './sales';
import payments from './payments';
import auctions from './auctions';
import invoices from './invoices';
import customers from './customers';
import returns from './returns';
import auctionDetails from './auctionDetails';

export default {
    auctions,
    auctionDetails,
    charts,
    customers,
    dashboard,
    followers,
    users,
    orders,
    app,
    userDetails,
    trucks,
    truckDetails,
    inventory,
    sales,
    payments,
    invoices,
    returns
};
