
export default function resolveAuctionDetails(auctionId) {
    let url = "https://recommerce-pist2.herokuapp.com/api/auctions/" + auctionId;
    return fetch(url, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got auctions!!', res);
          return res.json();
      });
}