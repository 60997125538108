import React, { useEffect } from 'react';
import { Spinner } from 'react-rainbow-components';
import ScansBarChart from './ScansBarChart';
import useGlobal from '../../store';

const ScansSection = (props) => {

    const [recentScans, kpiActions] = useGlobal(
        state => state.recentScans,
        actions => actions.kpiActions
    );
    const [dailyScans] = useGlobal(
        state => state.dailyScans
    );

    useEffect(() => {
        kpiActions.getRecentScans();
        kpiActions.getDailyScans();
    }, []);

    const getDailyScansDatasets = (data) => {
        return [
            {
                title: 'AUCTION',
                color: '#0061a5',
                values: data.map(row => parseInt(row.auction_scans))
            },
            {
                title: 'PALLET',
                color: '#CCCCCC',
                values: data.map(row => parseInt(row.pallet_scans))
            }
        ];
    }

    const getHourlyScansDatasets = (data) => {
        return [
            {
                title: 'AUCTION',
                color: '#0061a5',
                values: data.map(row => parseInt(row.auction_scans))
            },
            {
                title: 'PALLET',
                color: '#CCCCCC',
                values: data.map(row => parseInt(row.pallet_scans))
            }
        ];
    }

    return (
        <div style={{padding:"20px"}}>
            <h1 style={{fontSize:"30px"}} className="react-rainbow-admin-header-pages_title">Scans</h1>
            <p style={{display:"flex",width:"50%", alignItems: "center", justifyContent:"space-between"}} >Scans over the last 12 hours and 2 weeks.</p>
            <div style={{display:"flex", margin:"10px 0px"}}>
            { recentScans ? <ScansBarChart title="Hourly Scans" badge={recentScans.reduce((pv,cv) => pv + parseInt(cv.total), 0).toLocaleString()} labels={recentScans.map(row => row.d.split(" ")[1])} datasets={getHourlyScansDatasets(recentScans)}></ScansBarChart> : <Spinner></Spinner> }
            { dailyScans ? <ScansBarChart title="Daily Scans" badge={dailyScans.reduce((pv,cv) => pv + parseInt(cv.total), 0).toLocaleString()} labels={dailyScans.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])} datasets={getDailyScansDatasets(dailyScans)}></ScansBarChart> : <Spinner></Spinner> }
            </div>
        </div>
    )
}

export default ScansSection;