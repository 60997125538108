import {
    STOP_LOADING_RETURNS,
    LOAD_RETURNS_DATA,
    START_LOADING_RETURNS,
} from '../../actions/returns';

const initialState = {
    returns: [],
    chartsData: {
        reasons: {
            title: "Reasons",
            labels: [],
            value: [],
        }
    },
    isLoading: true,
    isFirstTime: true,
    start: null,
    end: null
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}


function loadData(state, action) {

    const newState = {
        ...state,
        returns: action.returns,
        chartsData: action.chartsData,
        isFirstTime: false,
        start: action.startTime,
        end: action.endTime
    };
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function returns(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_RETURNS:
            return startLoading(state);
        case LOAD_RETURNS_DATA:
            return loadData(state, action);
        case STOP_LOADING_RETURNS:
            return stopLoading(state);
        default:
            return state;
    }
}
