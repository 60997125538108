import React from 'react';
import PropTypes from 'prop-types';

export default function CompletedTasks(props) {
    const {
        className,
        style,
    } = props;

    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
            <g>
                <g transform="translate(-147.000000, -650.000000)">
                    <g transform="translate(129.000000, 633.000000)">
                        <g transform="translate(18.000000, 17.000000)">
                            <path d="M10.351205,0.376944099 L8.83100621,0.376944099 C8.53783851,0.376944099 8.3002236,0.556919255 8.3002236,0.778732919 C8.3002236,1.00054658 8.53783851,1.18052174 8.83100621,1.18052174 L8.90176398,1.18052174 C9.19493168,1.18052174 9.43254658,1.36029814 9.43254658,1.58221118 C9.43254658,1.80402484 9.19493168,1.98380124 8.90176398,1.98380124 L5.63359006,1.98380124 C5.34042236,1.98380124 5.10280745,2.16367702 5.10280745,2.38559006 C5.10280745,2.60740373 5.34042236,2.7872795 5.63359006,2.7872795 L9.56104348,2.7872795 L17.5888696,2.7872795 C17.8820373,2.7872795 18.1196522,2.60740373 18.1196522,2.38559006 C18.1196522,2.16367702 17.8820373,1.98380124 17.5888696,1.98380124 L16.6818385,1.98380124 C16.3887702,1.98380124 16.1511553,1.80402484 16.1511553,1.58221118 C16.1511553,1.36029814 16.3887702,1.18052174 16.6818385,1.18052174 L20.2442733,1.18052174 C20.5373416,1.18052174 20.7750559,1.00054658 20.7750559,0.778732919 C20.7750559,0.556919255 20.5373416,0.376944099 20.2442733,0.376944099 L19.9784348,0.376944099 L10.351205,0.376944099 Z" id="path-1_55_" fill="#CCCFDB" />
                            <path d="M19.7801739,2.89490683 C19.5344099,2.89490683 19.3351553,2.7021118 19.3351553,2.46429814 C19.3351553,2.22668323 19.5344099,2.0338882 19.7801739,2.0338882 L21.1437516,2.0338882 C21.3895155,2.0338882 21.5887702,2.22668323 21.5887702,2.46429814 C21.5887702,2.7021118 21.3895155,2.89490683 21.1437516,2.89490683 L19.7801739,2.89490683 Z" id="path-2_55_" fill="#E3E5ED" />
                            <path d="M10.351205,6.14081988 L8.83100621,6.14081988 C8.53783851,6.14081988 8.3002236,6.32079503 8.3002236,6.5426087 C8.3002236,6.76442236 8.53783851,6.94429814 8.83100621,6.94429814 L8.90176398,6.94429814 C9.19493168,6.94429814 9.43254658,7.12427329 9.43254658,7.34608696 C9.43254658,7.56790062 9.19493168,7.74767702 8.90176398,7.74767702 L5.63359006,7.74767702 C5.34042236,7.74767702 5.10280745,7.9275528 5.10280745,8.14946584 C5.10280745,8.3712795 5.34042236,8.55095652 5.63359006,8.55095652 L9.56104348,8.55095652 L17.5888696,8.55095652 C17.8820373,8.55095652 18.1196522,8.3712795 18.1196522,8.14946584 C18.1196522,7.9275528 17.8820373,7.74767702 17.5888696,7.74767702 L16.6818385,7.74767702 C16.3887702,7.74767702 16.1511553,7.56790062 16.1511553,7.34608696 C16.1511553,7.12427329 16.3887702,6.94429814 16.6818385,6.94429814 L20.2442733,6.94429814 C20.5373416,6.94429814 20.7750559,6.76442236 20.7750559,6.5426087 C20.7750559,6.32079503 20.5373416,6.14081988 20.2442733,6.14081988 L19.9784348,6.14081988 L10.351205,6.14081988 Z" id="path-3_55_" fill="#A4A7B5" />
                            <path d="M19.7801739,8.65858385 C19.5344099,8.65858385 19.3351553,8.46598758 19.3351553,8.22827329 C19.3351553,7.99055901 19.5344099,7.79776398 19.7801739,7.79776398 L21.1437516,7.79776398 C21.3895155,7.79776398 21.5887702,7.99055901 21.5887702,8.22827329 C21.5887702,8.46598758 21.3895155,8.65858385 21.1437516,8.65858385 L19.7801739,8.65858385 Z" id="path-4_55_" fill="#A4A7B5" />
                            <path d="M10.351205,12.6252919 L8.83100621,12.6252919 C8.53783851,12.6252919 8.3002236,12.8052671 8.3002236,13.0269814 C8.3002236,13.2488944 8.53783851,13.4287702 8.83100621,13.4287702 L8.90176398,13.4287702 C9.19493168,13.4287702 9.43254658,13.6084472 9.43254658,13.8303602 C9.43254658,14.0521739 9.19493168,14.2321491 8.90176398,14.2321491 L5.63359006,14.2321491 C5.34042236,14.2321491 5.10280745,14.4120248 5.10280745,14.6338385 C5.10280745,14.8556522 5.34042236,15.035528 5.63359006,15.035528 L9.56104348,15.035528 L17.5888696,15.035528 C17.8820373,15.035528 18.1196522,14.8556522 18.1196522,14.6338385 C18.1196522,14.4120248 17.8820373,14.2321491 17.5888696,14.2321491 L16.6818385,14.2321491 C16.3887702,14.2321491 16.1511553,14.0521739 16.1511553,13.8303602 C16.1511553,13.6084472 16.3887702,13.4287702 16.6818385,13.4287702 L20.2442733,13.4287702 C20.5373416,13.4287702 20.7750559,13.2488944 20.7750559,13.0269814 C20.7750559,12.8052671 20.5373416,12.6252919 20.2442733,12.6252919 L19.9784348,12.6252919 L10.351205,12.6252919 Z" id="path-5_55_" fill="#E3E5ED" />
                            <path d="M19.7801739,15.1429565 C19.5344099,15.1429565 19.3351553,14.9501615 19.3351553,14.7124472 C19.3351553,14.4747329 19.5344099,14.2819379 19.7801739,14.2819379 L21.1437516,14.2819379 C21.3895155,14.2819379 21.5887702,14.4747329 21.5887702,14.7124472 C21.5887702,14.9501615 21.3895155,15.1429565 21.1437516,15.1429565 L19.7801739,15.1429565 Z" id="path-6_53_" fill="#E3E5ED" />
                            <circle id="circle-1_5_" fill="#1DE9B6" cx="1.68944099" cy="1.65147826" r="1.59006211" />
                            <path d="M1.57485714,6.35806211 C0.705590062,6.35806211 0.000795031056,7.06285714 0.000795031056,7.93212422 C0.000795031056,8.80129193 0.705590062,9.50608696 1.57485714,9.50608696 C2.4442236,9.50608696 3.14891925,8.80129193 3.14891925,7.93212422 C3.14891925,7.06275776 2.4442236,6.35806211 1.57485714,6.35806211 Z" id="path-7_52_" fill="#1DE9B6" />
                            <circle id="circle-2_4_" fill="#1DE9B6" cx="1.68944099" cy="14.2725963" r="1.68944099" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

CompletedTasks.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

CompletedTasks.defaultProps = {
    className: undefined,
    style: undefined,
};
