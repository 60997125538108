import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchPaymentsData from '../../../redux/actions/payments';
import ContentPage from './contentPage';
import './styles.css';

class Payments extends Component {

    componentDidMount() {
        const { fetchPaymentsData } = this.props;
        console.log('fetching payments data...', this.props.match.params);
        
        let start = this.props.match.params.start ? this.props.match.params.start : null;
        let end = this.props.match.params.end ? this.props.match.params.end : null;
        fetchPaymentsData(start, end);
    }

    componentDidUpdate() {
        const { fetchPaymentsData } = this.props;
        console.log('fetching payments data2...', this.props.match.params);
        
        let start = this.props.match.params.start ? this.props.match.params.start : null;
        let end = this.props.match.params.end ? this.props.match.params.end : null;
        fetchPaymentsData(start, end);
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Payments" />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title="Payments"
                        description="" />
                </div>
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Payments.propTypes = {
    fetchPaymentsData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.payments;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaymentsData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Payments);
