/* eslint-disable no-console */
import {
    resolveChartsData,
} from '../../services/customers';

export const START_LOADING_CUSTOMERS = 'START_LOADING_CUSTOMERS';
export const STOP_LOADING_CUSTOMERS = 'STOP_LOADING_CUSTOMERS';
export const LOAD_CUSTOMERS_DATA = 'LOAD_CUSTOMERS_DATA';

export default function fetchCustomersData() {
    return (dispatch, getState) => {
        const { isFirstTime } = getState().customers;
        if (isFirstTime) {
            const promises = [
                resolveChartsData()
            ];
            dispatch({ type: START_LOADING_CUSTOMERS });
            return Promise.all(promises)
                .then((results) => {
                    const [chartsData] = results;
                    dispatch({
                        type: LOAD_CUSTOMERS_DATA,
                        chartsData
                    });
                    dispatch({ type: STOP_LOADING_CUSTOMERS });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_CUSTOMERS });
                });
        }
        return null;
    };
}

