import React, { useEffect } from 'react';
import { Spinner } from 'react-rainbow-components';
import ScansBarChart from './ScansBarChart';
import useGlobal from '../../store';

const CustomersSection = (props) => {

    const [dailyBids, kpiActions] = useGlobal(
        state => state.dailyBids,
        actions => actions.kpiActions
    );
    const [dailyNewUsers] = useGlobal(
        state => state.dailyNewUsers
    );

    useEffect(() => {
        kpiActions.getDailyNewUsers();
    }, []);

    const getDailyUniqueBidderDatasets = (data) => {
        return [
            {
                title: 'UNIQUE BIDDERS',
                color: '#0061a5',
                values: data.map(row => parseInt(row.total_unique))
            }
        ];
    }

    const getDailyNewUsersDatasets = (data) => {
        return [
            {
                title: 'BIDDERS',
                color: '#0061a5',
                values: data.map(row => parseInt(row.total_bidders))
            },
            {
                title: 'NON-BIDDERS',
                color: '#CCCCCC',
                values: data.map(row => parseInt(row.total - row.total_bidders))
            }
        ];
    }

    return (
        <div style={{padding:"20px"}}>
            <h1 style={{fontSize:"30px"}} className="react-rainbow-admin-header-pages_title">Customers</h1>
            <p style={{display:"flex",width:"50%", alignItems: "center", justifyContent:"space-between"}} >Unique bidders and new users over the last 2 weeks.</p>
            <div style={{display:"flex", margin:"10px 0px"}}>
            { dailyBids ? <ScansBarChart title="Daily Unique Bidders" badge={dailyBids.reduce((pv,cv) => pv + parseInt(cv.total_unique), 0).toLocaleString()} labels={dailyBids.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])}  datasets={getDailyUniqueBidderDatasets(dailyBids)}></ScansBarChart> : <Spinner></Spinner> }
            { dailyNewUsers ? <ScansBarChart title="Daily New Users" subtitle="New Sign-Ups" badge={'NEW BIDDERS: ' + dailyNewUsers.reduce((pv,cv) => pv + parseInt(cv.total_bidders), 0).toLocaleString()} labels={dailyNewUsers.map(row => row.d.split("-")[1] + "-" + row.d.split("-")[2])}  datasets={getDailyNewUsersDatasets(dailyNewUsers)}></ScansBarChart> : <Spinner></Spinner> }
            </div>
        </div>
    )
}

export default CustomersSection;