/* eslint-disable no-console */
import {
    resolveTruckDetailCharts,
    resolveTruckDetails,
} from '../../services/trucks';

export const START_LOADING_TRUCK_DETAILS = 'START_LOADING_TRUCK_DETAILS';
export const STOP_LOADING_TRUCK_DETAILS = 'STOP_LOADING_TRUCK_DETAILS';
export const LOAD_TRUCK_DETAILS_DATA = 'LOAD_TRUCK_DETAILS_DATA';

export default function fetchTruckDetailsData(truckId) {
    return (dispatch, getState) => {
        //const { isFirstTime } = getState().truckDetails;
        //if (isFirstTime) {
            const promises = [
                resolveTruckDetails(truckId),
            ];
            dispatch({ type: START_LOADING_TRUCK_DETAILS });
            return Promise.all(promises)
                .then((results) => {
                    const [truckDetails] = results;
                    let chartsData = resolveTruckDetailCharts(truckDetails);
                    dispatch({
                        type: LOAD_TRUCK_DETAILS_DATA,
                        truckDetails,
                        chartsData
                    });
                    dispatch({ type: STOP_LOADING_TRUCK_DETAILS });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_TRUCK_DETAILS });
                });
        //}
        //return null;
    };
}

