import React, { useEffect } from 'react';
import ScansSection from './ScansSection';
import BidsSection from './BidsSection';
import RevenueSection from './RevenueSection';
import CustomersSection from './CustomersSection';

const QuickDashboard = (props) => {

    useEffect(() => {
        
    }, []);

    return (<div>
        <ScansSection></ScansSection>
        <BidsSection></BidsSection>
        <RevenueSection></RevenueSection>
        <CustomersSection></CustomersSection>
    </div>)
}

export default QuickDashboard;