import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './styles.css';

export default function PercentCell({ value }) {
    return (
        <div style={{textAlign: 'right'}}>
            <NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={'%'} />
        </div>
    );
}

PercentCell.propTypes = {
    value: PropTypes.string,
};

PercentCell.defaultProps = {
    value: undefined,
};
