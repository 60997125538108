import React, {  } from 'react';
import ChartsSection from './chartsSection';
import SourcesTabs from './sourcesTabs';

const ContentPage = (props) => {

    const chartsData = [];
    const sales = [];

    return (<div>
        <ChartsSection {...props} chartsData={chartsData} />
        <SourcesTabs sales={sales} />
    </div>);

}
export default ContentPage;