import React, {} from 'react';
import { Badge } from 'react-rainbow-components';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LocationDashboardStat = (props) => {

    return (
    <article className={"rainbow-card react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small" }>
        <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">{props.stat}</p>
        <div className="rainbow-flex rainbow-justify_spread">
            <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{props.currency || ''}{props.value.toFixed(props.decimal || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{props.append || ''}</h1>
            {props.compare ? 
                <Badge variant="brand" className={props.value < props.compare ? "react-rainbow-admin-dashboard_badge--error" : "react-rainbow-admin-dashboard_badge--success"}>
                    { props.value > props.compare ? 
                        <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown}/> }
                    {props.currency || ' '}
                    {(props.value - props.compare).toFixed(props.decimal || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Badge> : <></>
            }
        </div>
    </article>
    )

}

export default LocationDashboardStat;