import {
    STOP_LOADING_PAYMENTS,
    LOAD_PAYMENTS_DATA,
    START_LOADING_PAYMENTS,
} from '../../actions/payments';

const initialState = {
    payments: [],
    chartsData: {
        channelValue: {
            title: 'Inventory Value by Channel',
            labels: ['AUCTION', 'POS/ONLINE', 'EBAY','NONE'],
            value: [0,0,0,0],
        }
    },
    isLoading: true,
    isFirstTime: true,
    start: null,
    end: null
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}

function getChartData(data) {
    let chart = initialState.chartsData;
    chart.channelCount.value[0] = data.filter(it => (it.sales_channel_id==1)).length;
    chart.channelCount.value[1] = data.filter(it => (it.sales_channel_id==2)).length;
    chart.channelCount.value[2] = data.filter(it => (it.sales_channel_id==3)).length;
    chart.channelCount.value[3] = data.filter(it => (!it.sales_channel_id)).length;
    chart.channelValue.value[0] = data.filter(it => (it.sales_channel_id==1)).reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2);
    chart.channelValue.value[1] = data.filter(it => (it.sales_channel_id==2)).reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2);
    chart.channelValue.value[2] = data.filter(it => (it.sales_channel_id==3)).reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2);
    chart.channelValue.value[3] = data.filter(it => (!it.sales_channel_id)).reduce((pv, cv) => pv + (cv.price * 1), 0).toFixed(2);
    return chart;
}
function loadData(state, action) {

    const newState = {
        ...state,
        payments: action.payments,
        isFirstTime: false,
        start: action.startTime,
        end: action.endTime
    };
    console.log('load data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function sales(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_PAYMENTS:
            return startLoading(state);
        case LOAD_PAYMENTS_DATA:
            return loadData(state, action);
        case STOP_LOADING_PAYMENTS:
            return stopLoading(state);
        default:
            return state;
    }
}
