import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import './styles.css';

export default function DateCell({ value }) {
    return (
        <div>{dateFormat(value, "mm/dd/yyyy")}</div>
    );
}

DateCell.propTypes = {
    value: PropTypes.string,
};

DateCell.defaultProps = {
    value: undefined,
};
