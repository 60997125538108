
export default function resolveInventoryTrucks(asOfDate = null) {
    let endpointUrl = "https://recommerce-pist2.herokuapp.com/api/inventorytrucks";
    endpointUrl += (asOfDate) ? '/' + asOfDate : '';
    return fetch(endpointUrl, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got inventory trucks!!', res);
          return res.json();
      });
}