import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-rainbow-components';
import InventoryTabs from './inventoryTabs';
import Tile from '../../../components/Tile';
import { ShoppingCartIcon, PricingIcon, SalesIcon } from '../../../components/icons';
import ChartsSection from './chartsSection';


export default function ContentPage(props) {
    const {
        inventory,
        inventoryTrucks,
        summary,
        chartsData,
        isLoading,
    } = props;

    if (isLoading) {
        return <Spinner size="large" />;
    }
    return (
        <div>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                        title="Total Items"
                        label={summary.total_items}
                         />
                <Tile
                        title="Total Retail Value"
                        label={summary.total_retail_value}
                         />
                <Tile
                        title="Total Cost"
                        label={summary.total_cost}
                         />
               
            </section>
            <ChartsSection chartsData={chartsData} />
            <InventoryTabs inventory={inventory} inventoryTrucks={inventoryTrucks} />
        </div>
    );
}

ContentPage.propTypes = {
    inventory: PropTypes.array.isRequired,
    inventoryTrucks: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
