import {
    STOP_LOADING_AUCTION_DETAILS,
    LOAD_AUCTION_DETAILS_DATA,
    START_LOADING_AUCTION_DETAILS,
} from '../../actions/auctionDetails';

const initialState = {
    lots: [],
    chartsData: {
        channelValue: {
            title: 'Inventory Value by Channel',
            labels: ['AUCTION', 'POS/ONLINE', 'EBAY','NONE'],
            value: [0,0,0,0],
        }
    },
    isLoading: true,
    isFirstTime: true,
    start: null,
    end: null
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}


function loadData(state, action) {
    console.log('LOADING AUCTION DETAIL DATA', action);
    const newState = {
        ...state,
        lots: action.auctionDetails.lots,
        isFirstTime: false,
        start: action.startTime,
        end: action.endTime
    };
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function auctions(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_AUCTION_DETAILS:
            return startLoading(state);
        case LOAD_AUCTION_DETAILS_DATA:
            return loadData(state, action);
        case STOP_LOADING_AUCTION_DETAILS:
            return stopLoading(state);
        default:
            return state;
    }
}
