import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchInventoryData from '../../../redux/actions/inventory';
import ContentPage from './contentPage';
import './styles.css';

class Inventory extends Component {
    componentDidMount() {
        const { fetchInventoryData } = this.props;
        let asOfDate = this.props.match.params.asof ? this.props.match.params.asof : null;
        fetchInventoryData(asOfDate);
    }

    componentDidUpdate() {
        const { fetchInventoryData } = this.props;
        console.log('fetching inventory data2...', this.props.match.params);
        let asOfDate = this.props.match.params.asof ? this.props.match.params.asof : null;
        fetchInventoryData(asOfDate);
    }

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Inventory" />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title="Inventory"
                        description="Current inventory report" />
                </div>
                <div className="content-wrapper">
                    <ContentPage {...this.props} />
                </div>
            </div>
        );
    }
}

Inventory.propTypes = {
    fetchInventoryData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.inventory;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchInventoryData
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Inventory);
