import React, { useState, useEffect } from 'react';
import { Badge } from 'react-rainbow-components';


const AuctionDashboardStat = (props) => {

    const [currentVal, setCurrentVal] = useState(props.current_bid);
    const [isFlashing, setIsFlashing] = useState(false);

    const flashUpdate = () => {
        setIsFlashing(true);
        setTimeout(() => {
            setIsFlashing(false);
        }, 2000);
    }

    useEffect(() => {
        if(props.value === currentVal) {
            return;
        }
        setCurrentVal(props.value);
        flashUpdate();
    }, [props]);

    return (
    <article className={isFlashing ? 
        "highlight rainbow-card react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small"
        : "rainbow-card react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small" }>
        <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">{props.stat}</p>
        <div className="rainbow-flex rainbow-justify_spread">
            <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{props.value}</h1>
            {props.compare ? 
                <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                    {props.compare}
                </Badge> : <></>
            }
        </div>
    </article>
    )

}

export default AuctionDashboardStat;