import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Application from 'react-rainbow-components/components/Application';
import Sidebar from 'react-rainbow-components/components/Sidebar';
import SidebarItem from 'react-rainbow-components/components/SidebarItem';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import Routes from '../routes';
import SectionHeading from '../components/SectionHeading';
import {
    DashboardIcon,
    PagesIcon,
} from '../components/icons';
import { navigate } from '../redux/actions/app';
import './styles.css';
import useGlobal from '../store';
import SignForm from '../sections/forms/signinForm';


const App = (props) => {
    
    const [error] = useGlobal(
        state => state.error
    );
    const [auth, authActions] = useGlobal(
        state => state.auth,
        actions => actions.authActions
    );
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const { isSidebarHidden } = props;
        const isSidebarOpen = !isSidebarHidden;
        document.body.style.overflow = 'auto';
        if (isSidebarOpen) {
            //document.body.style.overflow = 'hidden';
        }
    }, []);

    const getSidebarClassNames = () => {
        const { isSidebarHidden } = props;
        return classnames('react-rainbow-admin-app_sidebar-container', {
            'react-rainbow-admin-app_sidebar-container--sidebar-hidden': isSidebarHidden,
        });
    }

    const handleOnSelect = (e, selectedItem) => {
        return setSelectedItem(selectedItem);
    }

    const loginSubmit = (formValues) => {
        console.log('FORM VALUES', formValues);
        authActions.getAuthToken(formValues);
    }

    return (
        <Application>
            { error ? <div style={{height:"50px", background:"red", color:"white", textAlign:"center", fontSize:"20px", lineHeight:"50px", margin:"0", padding:"0"}}>{error}</div> : <></> }
            {!auth && (process.env.NODE_ENV !== "development") ? <SignForm onSubmit={loginSubmit}></SignForm> : <> 
            <RenderIf isTrue={!props.isSidebarHidden}>
                <div
                    className="react-rainbow-admin-app_backdrop"
                    role="presentation"
                    onClick={props.toogleSidebar} />
            </RenderIf>
            <SectionHeading onToogleSidebar={props.toogleSidebar} />
            <div className={getSidebarClassNames()}>
                <Sidebar
                    className="react-rainbow-admin-app_sidebar"
                    selectedItem={selectedItem}
                    onSelect={() => handleOnSelect(selectedItem)}>
                    <SidebarItem
                        className="react-rainbow-admin-app_sidebar-item"
                        icon={<DashboardIcon />}
                        name="dashboard"
                        label="Dashboard"
                        onClick={navigate('/dashboard')} />
                    <SidebarItem
                        className="react-rainbow-admin-app_sidebar-item"
                        icon={<PagesIcon />}
                        name="reports"
                        label="Reports"
                        onClick={navigate('/reports')} />
                    <SidebarItem
                        style={{fontSize:"23px"}}
                        className="react-rainbow-admin-app_sidebar-item"
                        icon={<FontAwesomeIcon icon={faCommentsDollar} />}
                        name="auctions"
                        label="Live Auctions"
                        onClick={navigate('/auctions')} />
                    
                </Sidebar>
                <RenderIf isTrue={!props.isSidebarHidden}>
                    <div className="react-rainbow-admin-app_sidebar-back-button-container">
                        <ButtonIcon
                            onClick={props.toogleSidebar}
                            size="large"
                            icon={
                                <FontAwesomeIcon className="react-rainbow-admin-app_sidebar-back-button-icon" icon={faArrowLeft} />
                            } />
                    </div>
                </RenderIf>
            </div>
            <div className="react-rainbow-admin-app_router-container">
                <Routes />
            </div>
            </> }
        </Application>
    );
    
}

export default App;


