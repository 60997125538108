
export default function resolveReturns(start=null, end=null) {
    let url = "https://recommerce-pist2.herokuapp.com/api/returns";
    url += (start) ? "/" + start : "";
    url += (end) ? "/" + end : "";
    return fetch(url, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got returns!!', res);
          return res.json();
      });
}