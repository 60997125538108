import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PageHeader from '../../../components/PageHeader';
import { navigateTo } from '../../../history';
import fetchReturnsData from '../../../redux/actions/returns';
import ContentPage from './contentPage';
import './styles.css';

class Returns extends Component {

    constructor(){
        super();
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    };

    componentDidMount() {
        const { fetchReturnsData } = this.props;
        console.log('fetching returns data...', this.props.match.params);
        
        let start = this.props.match.params.start ? this.props.match.params.start : null;
        let end = this.props.match.params.end ? this.props.match.params.end : null;
        fetchReturnsData(start, end);
    }

    componentDidUpdate() {
        const { fetchReturnsData } = this.props;
        console.log('fetching returns data2...', this.props.match.params);
        
        let start = this.props.match.params.start ? this.props.match.params.start : null;
        let end = this.props.match.params.end ? this.props.match.params.end : null;
        fetchReturnsData(start, end);
    }

    forceUpdateHandler() {
        console.log('force update');
        //this.forceUpdate();
    };

    render() {
        return (
            <div>
                <div className="react-rainbow-admin-users_header-container">
                    <Breadcrumbs>
                        <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                        <Breadcrumb label="Returns" />
                    </Breadcrumbs>
                    <PageHeader
                        className="react-rainbow-admin-users_header"
                        title="Returns"
                        description="" />
                </div>
                <ContentPage {...this.props} updateData={this.forceUpdateHandler} />
            </div>
        );
    }
}

Returns.propTypes = {
    fetchReturnsData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.returns;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchReturnsData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Returns);
