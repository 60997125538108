import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-rainbow-components';
import ChartsSection from './chartsSection';
import InventoryTabs from './inventoryTabs';
import Tile from '../../../components/Tile';
import Puzzle from '../../../components/icons/Puzzle';
import { ShoppingCartIcon, PricingIcon, SalesIcon } from '../../../components/icons';
import CompletedTasks from '../../../components/icons/CompletedTasks';


export default function ContentPage(props) {
    const {
        items,
        chartsData,
        isLoading,
    } = props;

    if (isLoading) {
        return <Spinner size="large" />;
    }
    console.log('CONTENT PAGE!!', props, chartsData);
    return (
        <div>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                        title="Total Cost"
                        label={chartsData.total_cost}
                        icon={<ShoppingCartIcon />} />
                <Tile
                        title="Total Sales"
                        label={chartsData.total_sales}
                        icon={<ShoppingCartIcon />} />
                <Tile
                        title="Profit/Loss"
                        label={chartsData.profit}
                        icon={<ShoppingCartIcon />} />
               
            </section>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                        title="Est. Retail Value"
                        label={chartsData.total_retail}
                        icon={<ShoppingCartIcon />} />
                <Tile
                        title="Potential Sales"
                        label={chartsData.potential_sales}
                        icon={<ShoppingCartIcon />} />
                <Tile
                        title="Avg. Recovery Rate"
                        label={chartsData.avg_recovery_rate}
                        icon={<ShoppingCartIcon />} />
               
            </section>
            <ChartsSection chartsData={chartsData} />
            <InventoryTabs items={items} />
        </div>
    );
}

ContentPage.propTypes = {
    items: PropTypes.array.isRequired,
    chartsData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
