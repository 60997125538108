import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDjWLdT_94-6VCQWuRdNUrYI-50M_3XLPs",
    authDomain: "recommerce-a0291.firebaseapp.com",
    databaseURL: "https://recommerce-a0291.firebaseio.com",
    projectId: "recommerce-a0291",
    storageBucket: "recommerce-a0291.appspot.com",
    messagingSenderId: "54057503014",
    appId: "1:54057503014:web:0de774f92c8bac316019e7"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

if (window.location.hostname === "localhost") {
    console.log('using firebase emulator...');
    //db.useEmulator("localhost", 8080);
}

export const auth = (token) => {
    return firebase.auth().signInWithCustomToken(token);
}

export const getToken = () => {
    let url = "https://recommerce-pist2.herokuapp.com/api/firebasetoken/mac_admin";
    return fetch(url, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got token!!', res);
          return res.json();
      });
}

export const getAuctionLots = () => {
    console.log('getting auction lots.....');
    return db.collection('auction-lots').get();
};

export const streamAuctionLots = (observer) => {
    console.log('streaming auction lots...');
    return db.collection('auction-lots')
        //.where('is_open', '==', true)
        .onSnapshot(observer);
};



