import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './styles.css';

export default function NumberCell({ value }) {
    return (
        <div style={{textAlign: 'right'}}>
            <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        </div>
    );
}

NumberCell.propTypes = {
    value: PropTypes.string,
};

NumberCell.defaultProps = {
    value: undefined,
};
