import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.css';

export default function AuctionIdCell({ value }) {
    return (
        <Link className="react-rainbow-admin-users_user-id-cell-container" to={`/reports/auction/${value.id}/${value.auction_number}`}>
            <div className="react-rainbow-admin-users_user-id-cell rainbow-color_brand">{value.auction_number}</div>
        </Link>
    );
}

AuctionIdCell.propTypes = {
    value: PropTypes.object,
};

AuctionIdCell.defaultProps = {
    value: undefined,
};
