import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.css';

export default function TruckIdCell({ value }) {
    return (
        <Link className="react-rainbow-admin-users_user-id-cell-container" to={`/reports/truck/${value.short_id}/${value.long_id}`}>
            <div className="react-rainbow-admin-users_user-id-cell rainbow-color_brand">{value.long_id}</div>
        </Link>
    );
}

TruckIdCell.propTypes = {
    value: PropTypes.object,
};

TruckIdCell.defaultProps = {
    value: undefined,
};
