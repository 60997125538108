import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Picklist, PicklistOption, DatePicker, Button, Input, RadioButtonGroup } from 'react-rainbow-components';
import { navigateTo } from '../../../history';
import * as qs from 'query-string';

import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Badge from 'react-rainbow-components/components/Badge';
import Dataset from 'react-rainbow-components/components/Dataset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

class ChartsSection extends PureComponent {
    
    constructor(props) {
        super(props);
        const beginning = this.props.match.params.start ?
            new Date(new Date(this.props.match.params.start).getTime() + 5 * 60 * 60 * 1000)
            : new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
        const ending = this.props.match.params.end ?
            new Date(new Date(this.props.match.params.end).getTime() + 5 * 60 * 60 * 1000) 
            : new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        
        const queryParams = qs.parse(this.props.location.search);
        let initialFindBy = queryParams.findby ? queryParams.findby : 'issue';
        const initialState = {
            startDate: beginning,
            endDate: ending,
            locale: { name: 'en-US', label: 'English (US)' },
            findby: initialFindBy
        };
        this.state = initialState;

        this.handleClick = this.handleClick.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
      
    handleClick() {
        console.log('click', this.state.startDate, this.state.endDate);
        console.log('findby: ' + this.state.findby);
        let s = this.formatDate(this.state.startDate);
        let e = this.formatDate(this.state.endDate);
      
        navigateTo('/reports/invoices/'+s+'/'+e + '?findby=' + this.state.findby);
        this.props.updateData();
    }

    handleRadioChange(event) {
        console.log('change', event.target.value);
        return this.setState({ findby: event.target.value });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    

    render() {
        const {
            chartsData,
            invoices
        } = this.props;
        const totalInvoices = invoices.length;
        const totalPaidInvoices = invoices.filter(i => i.date_paid).length;
        const grandTotal = invoices.reduce((pv, cv) => pv + parseFloat(cv.grand_total), 0);
        const totalAmount = invoices.reduce((pv, cv) => pv + parseFloat(cv.total_price), 0);
        const totalTax = invoices.reduce((pv, cv) => pv + parseFloat(cv.total_tax), 0);
        const totalPremium = invoices.reduce((pv, cv) => pv + parseFloat(cv.total_premium), 0);
        const totalLotFee = invoices.reduce((pv, cv) => pv + parseFloat(cv.total_lot_fee), 0);
        const { findby } = this.state;
        
        return (
            <div style={{padding: "0 30px"}}>
            <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Invoices
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{totalInvoices ? totalInvoices.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Paid
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{totalPaidInvoices ? totalPaidInvoices.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Grand Total
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${grandTotal ? grandTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Bid Amount
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${totalAmount ? totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Tax
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${totalTax ? totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Premium
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${totalPremium ? totalPremium.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Lot Fee
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${totalLotFee ? totalLotFee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <div className="date-range">
                    <DatePicker
                        id="datePicker1"
                        value={this.state.startDate}
                        onChange={value => this.setState({ startDate: value })}
                        label="Start Date"
                        formatStyle="medium"
                    />
                    <DatePicker
                        id="datePicker2"
                        value={this.state.endDate}
                        onChange={value => this.setState({ endDate: value })}
                        label="End Date"
                        formatStyle="medium"
                    />
                    <RadioButtonGroup
                        id="radioButtonGroup"
                        options={[ { value: 'issue', label: 'Issue'}, { value: 'paid', label: 'Paid'}]}
                        value={findby}
                        variant="brand"
                        onChange={this.handleRadioChange}
                        label='Find By'
                    />
                    <Button label="GO" onClick={this.handleClick} variant="neutral" className="rainbow-m-around_medium" />
                </div>
            </section>
            </div>
        );
    }
}

ChartsSection.propTypes = {
    chartsData: PropTypes.object.isRequired,
};

export default withRouter(ChartsSection);
