/* eslint-disable no-console */
import {
    resolveAuctions,
    resolveAuctionDetails
} from '../../services/auctions';

export const START_LOADING_AUCTION_DETAILS = 'START_LOADING_AUCTION_DETAILS';
export const STOP_LOADING_AUCTION_DETAILS = 'STOP_LOADING_AUCTION_DETAILS';
export const LOAD_AUCTION_DETAILS_DATA = 'LOAD_AUCTION_DETAILS_DATA';


export default function fetchAuctionDetailsData(auctionId) {
    return (dispatch, getState) => {
        const promises = [
            resolveAuctionDetails(auctionId),
        ];
        dispatch({ type: START_LOADING_AUCTION_DETAILS });
        return Promise.all(promises)
            .then((results) => {
                const [auctionDetails] = results;
                let chartsData = []; //resolveTruckDetailCharts(auctionDetails);
                auctionDetails.lots = auctionDetails.lots.map(lot => {
                    lot.profit = (lot.sale_price - lot.cost).toFixed(2).toString();
                    return lot;
                });
                dispatch({
                    type: LOAD_AUCTION_DETAILS_DATA,
                    auctionDetails,
                    chartsData
                });
                dispatch({ type: STOP_LOADING_AUCTION_DETAILS });
            }).catch((error) => {
                console.log(error);
                dispatch({ type: STOP_LOADING_AUCTION_DETAILS });
            });
    };
}

