import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Picklist, PicklistOption, DatePicker, Button } from 'react-rainbow-components';
import { navigateTo } from '../../../history';

import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Badge from 'react-rainbow-components/components/Badge';
import Dataset from 'react-rainbow-components/components/Dataset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

class ChartsSection extends PureComponent {
    
    constructor(props) {
        super(props);
        const beginning = this.props.match.params.start ?
            new Date(new Date(this.props.match.params.start).getTime() + 5 * 60 * 60 * 1000)
            : new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        const ending = this.props.match.params.end ?
            new Date(new Date(this.props.match.params.end).getTime() + 5 * 60 * 60 * 1000) 
            : new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        const initialState = {
            startDate: beginning,
            endDate: ending,
            locale: { name: 'en-US', label: 'English (US)' }
        };
        this.state = initialState;

        this.handleClick = this.handleClick.bind(this);
    }
      
    handleClick() {
        console.log('click', this.state.startDate, this.state.endDate);
        let s = this.formatDate(this.state.startDate);
        let e = this.formatDate(this.state.endDate);
        navigateTo('/reports/returns/'+s+'/'+e);
        this.props.updateData();
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    render() {
        const {
            chartsData,
            returns
        } = this.props;

        const totalReturns = returns ? returns.length : 0;

        return (
            <div style={{padding: "0 30px"}}>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card className="react-rainbow-admin-users_card rainbow-p-vertical_small rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Total Returns
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{totalReturns ? totalReturns.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h1>
                    </div>
                </Card>
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.reasons.title}
                    >
                    
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.reasons.labels}
                            type="doughnut"
                            showLegend={true}
                            maintainAspectRatio={false}
                            chartLegendPosition="right"
                            >
                            <Dataset 
                                values={chartsData.reasons.value} 
                                backgroundColor={['#fe4849', '#ffcc00', '#ffaa37', '#1ad1a3', '#005500']} 
                                 />
                        </Chart>
                        
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.authorizers.title}
                    >
                    
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.authorizers.labels}
                            type="doughnut"
                            showLegend={true}
                            maintainAspectRatio={false}
                            chartLegendPosition="right"
                            >
                            <Dataset 
                                values={chartsData.authorizers.value} 
                                backgroundColor={['#fe4849', '#ffcc00', '#ffaa37', '#1ad1a3', '#005500']} 
                                 />
                        </Chart>
                        
                    </div>
                </Card>
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.scanners.title}
                    >
                    
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.scanners.labels}
                            type="doughnut"
                            showLegend={true}
                            maintainAspectRatio={false}
                            chartLegendPosition="right"
                            >
                            <Dataset 
                                values={chartsData.scanners.value} 
                                backgroundColor={['#fe4849', '#ffcc00', '#ffaa37', '#1ad1a3', '#005500']} 
                                 />
                        </Chart>
                        
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.sources.title}
                    >
                    
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.sources.labels}
                            type="doughnut"
                            showLegend={true}
                            maintainAspectRatio={false}
                            chartLegendPosition="right"
                            >
                            <Dataset 
                                values={chartsData.sources.value} 
                                backgroundColor={['#fe4849', '#ffcc00', '#ffaa37', '#1ad1a3', '#005500']} 
                                 />
                        </Chart>
                        
                    </div>
                </Card>
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.locations.title}
                    >
                    
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.locations.labels}
                            type="doughnut"
                            showLegend={true}
                            maintainAspectRatio={false}
                            chartLegendPosition="right"
                            >
                            <Dataset 
                                values={chartsData.locations.value} 
                                backgroundColor={['#fe4849', '#ffcc00', '#ffaa37', '#1ad1a3', '#005500']} 
                                 />
                        </Chart>
                        
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <div className="date-range">
                    <DatePicker
                        id="datePicker1"
                        value={this.state.startDate}
                        onChange={value => this.setState({ startDate: value })}
                        label="Start Date"
                        formatStyle="medium"
                    />
                    <DatePicker
                        id="datePicker2"
                        value={this.state.endDate}
                        onChange={value => this.setState({ endDate: value })}
                        label="End Date"
                        formatStyle="medium"
                    />
                    <Button label="GO" onClick={this.handleClick} variant="neutral" className="rainbow-m-around_medium" />
                </div>
            </section>
            </div>
        );
    }
}

ChartsSection.propTypes = {
    chartsData: PropTypes.object.isRequired,
};

export default withRouter(ChartsSection);
