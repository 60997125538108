import React, {} from 'react';
import { Badge, Card, Chart, Dataset } from 'react-rainbow-components';

const LocationDashboardBarChart = (props) => {

    return (
        <Card
            className={props.full ? "react-rainbow-admin-full_card" : "react-rainbow-admin-users_card"}
            title={props.title}
            actions={(
                props.badge ? 
                <Badge variant="brand" className="">
                    {props.badge}
                </Badge> : <></>
            )}>
            <h1 className={"react-rainbow-admin-users_card-title"}>{props.title}</h1>
            <div className={props.full ? "react-rainbow-admin-full_chart" : "react-rainbow-admin-users_chart"}>
                <Chart
                    labels={props.labels}
                    type="bar"
                    showStacked={true}
                    showLegend={false}
                    maintainAspectRatio={false}>
                    { props.datasets.map(dataset => 
                        <Dataset key={dataset.title} title={dataset.title} values={dataset.values} borderColor={dataset.color} backgroundColor={dataset.color} />
                        )}
                    
                </Chart>
            </div>
        </Card>
);

}
export default LocationDashboardBarChart;