
export default function resolveInvoices(start=null, end=null, findby=null) {
    let url = "https://recommerce-pist2.herokuapp.com/api/invoicesreport";
    url += (start) ? "/" + start : "";
    url += (end) ? "/" + end : "";
    if(findby) {
        url += "?findby=" + findby;
    }
    return fetch(url, {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('got invoices!!', res);
          return res.json();
      });
}