import {
    STOP_LOADING_AUCTION,
    LOAD_AUCTION_DATA,
    START_LOADING_AUCTION,
} from '../../actions/auctions';

const initialState = {
    auctions: [],
    chartsData: {
        washAuctionSales: {
            labels: [],
            value: [],
        },
        butlerAuctionSales: {
            labels: [],
            value: [],
        },
    },
    isLoading: true,
    isFirstTime: true,
    start: null,
    end: null
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}


function loadData(state, action) {
    console.log('loading auction data...', action);
    const newState = {
        ...state,
        chartsData: action.chartsData,
        auctions: action.auctions,
        isFirstTime: false,
        start: action.startTime,
        end: action.endTime
    };
    console.log('load auction data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function auctions(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_AUCTION:
            return startLoading(state);
        case LOAD_AUCTION_DATA:
            return loadData(state, action);
        case STOP_LOADING_AUCTION:
            return stopLoading(state);
        default:
            return state;
    }
}
