import * as apiClient from '../../services/api-client';

export const getTruckSourceList = async (store) => {
    let truckSourceList = await apiClient.trucks.getTruckSourceList();
    console.log('GOT TRUCK SOURCE LIST', truckSourceList);
    store.setState({ truckSourceList });
};

export const getTruckSources = async (store, start=null, end=null, sourceId=0, buildingId=0, includeOpen=1) => {
    let truckSources = await apiClient.trucks.getTruckSources(start, end, sourceId, buildingId, includeOpen);
    truckSources = truckSources.map(ts => {
        ts.avg_revenue = (ts.total_revenue / ts.total_trucks).toFixed(2);
        ts.roi = (((ts.total_revenue - (parseFloat(ts.total_cost) + parseFloat(ts.total_freight))) / (parseFloat(ts.total_cost) + parseFloat(ts.total_freight))) * 100).toFixed(2);
        ts.avg_cost = (ts.avg_cost * 1).toFixed(2);
        ts.avg_freight = ts.total_trucks !== "0" ? (parseFloat(ts.total_freight) / parseInt(ts.total_trucks)).toFixed(2) : "0.00";
        ts.avg_profit = ( (ts.avg_revenue) - (parseFloat(ts.avg_cost) + parseFloat(ts.avg_freight)) ).toFixed(2);
        return ts;
    })
    console.log('GOT TRUCK SOURCES', truckSources);
    store.setState({ truckSources });
};

export const getTruckSourceLocations = async (store, start=null, end=null, sourceId=0, buildingId=0, includeOpen=1) => {
    let truckSourceLocations = await apiClient.trucks.getTruckSourceLocations(start, end, sourceId, buildingId, includeOpen);
    truckSourceLocations = truckSourceLocations.map(ts => {
        ts.avg_revenue = (ts.total_revenue / ts.total_trucks).toFixed(2);
        ts.roi = (((ts.total_revenue - (parseFloat(ts.total_cost) + parseFloat(ts.total_freight)) ) / (parseFloat(ts.total_cost) + parseFloat(ts.total_freight))) * 100).toFixed(2);
        ts.avg_cost = (ts.avg_cost * 1).toFixed(2);
        ts.avg_freight = ts.total_trucks !== "0" ? (parseFloat(ts.total_freight) / parseInt(ts.total_trucks)).toFixed(2) : "0.00";
        ts.avg_profit = ((ts.avg_revenue) - (parseFloat(ts.avg_cost) + parseFloat(ts.avg_freight))).toFixed(2);
        return ts;
    })
    console.log('GOT TRUCK SOURCE LOCATIONS', truckSourceLocations);
    store.setState({ truckSourceLocations });
};

export const getTruckData = async (store, start=null, end=null, sourceId=0, buildingId=0, includeOpen=1) => {
    let truckData = await apiClient.trucks.getTruckData(start, end, sourceId, buildingId, includeOpen);
    truckData = truckData.map(ts => {
        ts.id_obj = {
            short_id: ts.id,
            long_id: ts.long_id
        }
        ts.profit = (ts.cost * 1 > 0) ? (ts.total_sales - (parseFloat(ts.cost) + parseFloat(ts.freight_cost))).toFixed(2) : "0.00";
        ts.roi = (ts.cost * 1 > 0) ? (((ts.total_sales - (parseFloat(ts.cost) + parseFloat(ts.freight_cost))) / (parseFloat(ts.cost) + parseFloat(ts.freight_cost))) * 100).toFixed(2) : "0.00";
        return ts;
    })
    store.setState({ truckData });
};

