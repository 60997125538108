import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-rainbow-components/components/Spinner';

import PageHeader from '../../components/PageHeader';
import fetchDashboardData from '../../redux/actions/dashboard';
import './styles.css';
import Card from 'react-rainbow-components/components/Card';
import Badge from 'react-rainbow-components/components/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';


function ContentPage(props) {
    const {
        totals: {
            sales_mtd,
            orders,
            sales_last_month,
            sales_last_month_delta,
            sales_ytd,
            scans_mtd,
            scans_last_month,
            scans_last_month_delta,
            inventory_age,
            inventory_age_delta,
            customers_total,
            customers_last_month,
            customers_this_month
        },
        chartsData,
        lastMessages,
        isLoading,
    } = props;

    if (isLoading) {
        return <Spinner size="large" />;
    } return (
        <div>
            <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Sales MTD
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${sales_mtd}</h1>
                    </div>
                </Card>

                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Sales Last Month
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${sales_last_month}</h1>
                        { sales_last_month_delta >= 0 ? 
                            <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                                <FontAwesomeIcon icon={faArrowUp} pull="left" /> {sales_last_month_delta}%
                            </Badge>
                            : <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--error">
                                <FontAwesomeIcon icon={faArrowDown} pull="left" /> {sales_last_month_delta}%
                            </Badge> }
                    </div>
                    
                    
                </Card>
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Sales YTD
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">${sales_ytd}</h1>
                    </div>
                    
                    
                </Card>
            </section>

            <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Scans MTD
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{scans_mtd}</h1>
                    </div>
                </Card>

                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Scans Last Month
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{scans_last_month}</h1>
                        { scans_last_month_delta >= 0 ? 
                            <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                                <FontAwesomeIcon icon={faArrowUp} pull="left" /> {scans_last_month_delta}%
                            </Badge>
                            : <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--error">
                                <FontAwesomeIcon icon={faArrowDown} pull="left" /> {scans_last_month_delta}%
                            </Badge> }
                    </div>
                    
                    
                </Card>

                <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                    <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                        Inventory Age
                    </p>
                    <div className="rainbow-flex rainbow-justify_spread">
                        <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{inventory_age} days</h1>
                        { inventory_age_delta < 0 ? 
                            <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                                <FontAwesomeIcon icon={faArrowDown} pull="left" /> {inventory_age_delta}
                            </Badge>
                            : <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--error">
                                <FontAwesomeIcon icon={faArrowUp} pull="left" /> {inventory_age_delta}
                            </Badge> }
                            
                    </div>
                    
                    
                </Card>
            </section>
            { (customers_this_month && customers_last_month) ? 
                <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                    
                    <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                        <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                            Customers Total
                        </p>
                        <div className="rainbow-flex rainbow-justify_spread">
                            <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{customers_total}</h1>

                            { parseInt(customers_total.replace(/,/g,'')) - parseInt(customers_this_month.replace(/,/g,'')) < 0 ? 
                                <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--error">
                                    <FontAwesomeIcon icon={faArrowDown} pull="left" /> {parseInt(customers_total.replace(/,/g,'')) - parseInt(customers_this_month.replace(/,/g,''))}
                                </Badge>
                                : <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                                    <FontAwesomeIcon icon={faArrowUp} pull="left" /> {parseInt(customers_total.replace(/,/g,'')) - parseInt(customers_this_month.replace(/,/g,''))}
                                </Badge> }
                        </div>
                    </Card>
        
                    <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small">
                        <p className="react-rainbow-admin-dashboard_chart-title rainbow-color_gray-3 rainbow-p-bottom_medium">
                            Customers Last Month
                        </p>
                        <div className="rainbow-flex rainbow-justify_spread">
                            <h1 className="react-rainbow-admin-dashboard_card--content rainbow-color_dark-1 rainbow-p-bottom_medium">{customers_this_month}</h1>
                            { parseInt(customers_this_month.replace(/,/g,'')) - parseInt(customers_last_month.replace(/,/g,'')) < 0 ? 
                                <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--error">
                                    <FontAwesomeIcon icon={faArrowDown} pull="left" /> {
                                        parseInt(customers_this_month.replace(/,/g,'')) - parseInt(customers_last_month.replace(/,/g,''))}
                                </Badge>
                                : <Badge variant="brand" className="react-rainbow-admin-dashboard_badge--success">
                                    <FontAwesomeIcon icon={faArrowUp} pull="left" /> {
                                        parseInt(customers_this_month.replace(/,/g,'')) - parseInt(customers_last_month.replace(/,/g,''))
                                    }
                                </Badge> }
                        </div>
                    </Card> 

                </section> 
                : <></> }

            
        </div>
    );
}

ContentPage.propTypes = {
    totals: PropTypes.object.isRequired,
    chartsData: PropTypes.object.isRequired,
    lastMessages: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

class Dashboard extends Component {
    componentDidMount() {
        const { fetchDashboardData } = this.props;
        fetchDashboardData();
    }

    render() {
        return (
            <div className="react-rainbow-admin-dashboard_view-port">
                <PageHeader
                    title="Key Performance Indicators (KPI's)"
                    description="Important metrics to watch." />
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Dashboard.propTypes = {
    fetchDashboardData: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.dashboard;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        fetchDashboardData,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Dashboard);
