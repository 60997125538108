import React from 'react';
import PropTypes from 'prop-types';

export default function BellIcon(props) {
    const {
        className,
        style,
    } = props;

    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17">
            <g>
                <g transform="translate(-147.000000, -418.000000)">
                    <g id="Group-52" transform="translate(129.000000, 403.000000)">
                        <g id="bell" transform="translate(18.000000, 16.000000)">
                            <path d="M10.2641509,2.41509434 L10.2641509,1.20754717 C10.2641509,0.540679245 9.7234717,0 9.05660377,0 C8.38973585,0 7.8490566,0.540679245 7.8490566,1.20754717 L7.8490566,2.43683019" id="Path" stroke="#DC9628" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.97841509,14.0793962 C8.20890566,14.6369811 10.0072453,14.6369811 13.2380377,14.0793962 L16.1029434,13.5849057 C14.6886038,12.1790189 13.8940377,10.4407547 13.8940377,8.45283019 L13.8940377,5.73584906 C13.9055094,3.91033962 12.6994717,2.57267925 11.4952453,2.02233962 C9.98611321,1.3325283 8.23033962,1.32981132 6.72362264,2.02475472 C5.53026415,2.57509434 4.23788679,3.91184906 4.22641509,5.73584906 L4.22641509,8.45283019 C4.22641509,10.4407547 3.52754717,12.1790189 2.11320755,13.5849057 L4.97841509,14.0793962 Z" id="Path" fill="#EEAF4B" />
                            <path d="M5.73584906,6.63879245 C5.73524528,6.63879245 5.73464151,6.63879245 5.73403774,6.63879245 C5.56739623,6.63758491 5.4330566,6.50173585 5.43396226,6.33509434 C5.44301887,4.92166038 6.56664151,4.02777358 7.22958491,3.72256604 C8.39154717,3.18671698 9.824,3.18762264 10.9938113,3.72196226 C11.1453585,3.79109434 11.2120755,3.97011321 11.1429434,4.12196226 C11.0738113,4.27350943 10.8947925,4.3405283 10.7426415,4.27079245 C9.73011321,3.808 8.48784906,3.80679245 7.48256604,4.27079245 C6.94913208,4.5165283 6.04498113,5.22867925 6.03803774,6.33871698 C6.03683019,6.50475472 5.90158491,6.63879245 5.73584906,6.63879245 Z" id="Path" fill="#FBD490" />
                            <path d="M6.61313208,14.3275472 C7.0490566,15.3141132 8.00241509,16 9.11184906,16 C10.2215849,16 11.1749434,15.3138113 11.6108679,14.3266415 C9.82128302,14.5536604 8.40150943,14.5539623 6.61313208,14.3275472 Z" id="Path" fill="#DC9628" />
                            <path d="M1.23622642,10.8579623 C1.1589434,10.8579623 1.08166038,10.8184891 1.02279245,10.7399456 C0.363169811,9.85985567 0,8.68814782 0,7.44071597 C0,6.19288134 0.363169811,5.02157627 1.02279245,4.14188911 C1.14083019,3.98439933 1.33162264,3.98439933 1.44966038,4.14188911 C1.56769811,4.29937889 1.56769811,4.55394037 1.44966038,4.71143015 C0.904150943,5.43926656 0.603773585,6.40837247 0.603773585,7.44071597 C0.603773585,8.47305947 0.904150943,9.44216539 1.44966038,10.1700018 C1.56769811,10.3274916 1.56769811,10.5820531 1.44966038,10.7395428 C1.39049057,10.8184891 1.31350943,10.8579623 1.23622642,10.8579623 Z" id="Path" fill="#F2ECBF" />
                            <path d="M2.50716981,9.94081589 C2.42988679,9.94081589 2.35260377,9.90134275 2.29373585,9.82279925 C1.28633962,8.47869849 1.28633962,6.29116141 2.29373585,4.94746343 C2.41177358,4.78997365 2.60256604,4.78997365 2.72060377,4.94746343 C2.83864151,5.10495321 2.83864151,5.3595147 2.72060377,5.51700448 C1.94837736,6.54733404 1.94837736,8.22333143 2.72060377,9.25325821 C2.83864151,9.41074799 2.83864151,9.66530947 2.72060377,9.82279925 C2.66173585,9.90174554 2.58445283,9.94081589 2.50716981,9.94081589 Z" id="Path" fill="#F2ECBF" />
                            <path d="M16.8769811,10.8579623 C16.7996981,10.8579623 16.7224151,10.8184891 16.6635472,10.7399456 C16.5455094,10.5824558 16.5455094,10.3278944 16.6635472,10.1704046 C17.2090566,9.44216539 17.509434,8.47305947 17.509434,7.44071597 C17.509434,6.40837247 17.2090566,5.43886377 16.6635472,4.71143015 C16.5455094,4.55394037 16.5455094,4.29937889 16.6635472,4.14188911 C16.7815849,3.98439933 16.9723774,3.98439933 17.0904151,4.14188911 C17.7500377,5.02197906 18.1132075,6.19328413 18.1132075,7.44071597 C18.1132075,8.68814782 17.7500377,9.85985567 17.0904151,10.7395428 C17.0315472,10.8184891 16.9542642,10.8579623 16.8769811,10.8579623 Z" id="Path" fill="#F2ECBF" />
                            <path d="M15.6060377,9.94081589 C15.5287547,9.94081589 15.4514717,9.90134275 15.3926038,9.82279925 C15.274566,9.66530947 15.274566,9.41074799 15.3926038,9.25325821 C16.1648302,8.22292864 16.1648302,6.54693126 15.3926038,5.51700448 C15.274566,5.3595147 15.274566,5.10495321 15.3926038,4.94746343 C15.5106415,4.78997365 15.701434,4.78997365 15.8194717,4.94746343 C16.8268679,6.2915642 16.8268679,8.47910128 15.8194717,9.82279925 C15.7606038,9.90174554 15.6833208,9.94081589 15.6060377,9.94081589 Z" id="Path" fill="#F2ECBF" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

BellIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

BellIcon.defaultProps = {
    className: undefined,
    style: undefined,
};
