import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Dataset from 'react-rainbow-components/components/Dataset';


export default class ChartsSection extends PureComponent {
    render() {
        const {
            chartsData,
        } = this.props;
        return (
            <>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-full_card"
                    title="Total Winning Bidders (Customers)"
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Monthly</h1>
                    <div className="react-rainbow-admin-full_chart">
                        <Chart
                            labels={chartsData.totalCustomers.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.totalCustomers.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-full_card"
                    title="New Winning Bidder Acquisition"
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Monthly</h1>
                    <div className="react-rainbow-admin-full_chart">
                        <Chart
                            labels={chartsData.newCustomers.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.newCustomers.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>
                
            </section>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-full_card"
                    title="Active (Unique) Winning Bidders"
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Monthly</h1>
                    <div className="react-rainbow-admin-full_chart">
                        <Chart
                            labels={chartsData.activeCustomers.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.activeCustomers.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>
                
            </section>
            </>
        );
    }
}

ChartsSection.propTypes = {
    chartsData: PropTypes.object.isRequired,
};
