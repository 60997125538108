import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import TablePagination from '../../../components/TablePagination';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import DateCell from '../../components/dateCell';
import { CSVLink } from "react-csv";


export default class InvoicesTabs extends Component {
    constructor(props) {
        console.log('INVOICES TABS PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'invoices',
            activePage: 1,
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    getCsvData() {
        const { invoices } = this.props;
        
        const csvData = [["invoiceID", "dateIssued", "amount", "premium", "lotFee", "shipping", "subtotal", "tax", "discount", "buyerAssurance", "lateFee", "grandTotal", "datePaid", "auction", "location", "customerName", "paymentId", "macbucks"]].concat(invoices.map(i => {
            return [
                i.id,
                i.date_created,
                i.total_price,
                i.total_premium,
                i.total_lot_fee,
                i.total_shipping,
                (i.total_price*1) + (i.total_premium*1) + (i.total_lot_fee *1),
                i.total_tax,
                i.discount,
                i.total_buyer_assurance,
                i.late_fee,
                i.grand_total,
                i.date_paid,
                i.auction_number,
                i.location,
                i.name,
                i.payment_id,
                i.mac_bucks_id
            ];
        }));
        return csvData;
        
    }
    getTableData() {
        const { invoices } = this.props;
        const { activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 200;
        const lastItem = firstItem + 200;

        return invoices.slice(firstItem, lastItem);
    }

    getPages() {
        const { invoices } = this.props;
        const { activeTabName } = this.state;
        return Math.ceil(invoices.length / 200);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="INVOICES"
                        name="invoices" />
                    <Tab
                        label="SEARCH"
                        name="search" />

                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName !== 'search'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" sortable field="id" defaultWidth={100} />
                            <Column header="ISSUED" field="date_created" component={DateCell} defaultWidth={100}/>  
                            <Column header="SUBTOTAL" field="total_price" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="TAX" field="total_tax" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="PREMIUM" field="total_premium" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="LOT FEE" field="total_lot_fee" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="SHIPPING" field="total_shipping" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="DISCOUNT" field="discount" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="ASSURANCE" field="total_buyer_assurance" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="TOTAL" field="grand_total" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="DATE PAID" field="date_paid" component={DateCell} defaultWidth={100}/> 
                            <Column header="AUCTION" field="auction_number" defaultWidth={150} />
                            <Column header="LOCATION" field="location" defaultWidth={150} />
                            <Column header="CUSTOMER" field="name" />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
       
                </div>
            </div>
        );
    }
}

InvoicesTabs.propTypes = {
    invoices: PropTypes.array.isRequired,
};
