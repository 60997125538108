import React, { useState } from 'react';
import LocationDashboardBarChart from './LocationDashboardBarChart';
import LocationDashboardStat from './LocationDashboardStat';



const LocationDashboard = (props) => {

    const totalScans = props.scans.reduce((pv,cv) => pv + parseInt(cv.total), 0);
    const totalPalletScans = props.scans.reduce((pv,cv) => pv + parseInt(cv.pallet_scans), 0);
    const totalLots = props.sales.reduce((pv,cv) => pv + parseInt(cv.total_lots), 0);
    const totalSales = props.sales.reduce((pv,cv) => pv + parseFloat(cv.total_sales), 0);
    const totalCogs = props.sales.reduce((pv,cv) => pv + parseFloat(cv.total_cogs), 0);
    const totalReturns = props.returns.reduce((pv,cv) => pv + parseInt(cv.total), 0);
    const totalTransfers = props.transfers.reduce((pv,cv) => pv + parseInt(cv.total_items), 0);

    const totalRetailValue = props.sales.reduce((pv,cv) => pv + parseFloat(cv.total_retail_value), 0);
    const totalRetailValueSales = props.sales.reduce((pv,cv) => pv + parseFloat(cv.retail_value_sales), 0);

    const scanChartLabels = props.scans.map(row => row.d);
    const scanChartDatasets = [
        {
            title: 'AUCTION',
            color: props.color,
            values: props.scans.map(row => parseInt(row.total) - parseInt(row.pallet_scans))
        },
        {
            title: 'PALLET',
            color: '#CCCCCC',
            values: props.scans.map(row => parseInt(row.pallet_scans))
        }
    ];

    const lotsChartLabels = props.sales.map(row => row.d);
    const lotsChartDatasets = [
        {
            title: 'LOTS',
            color: props.color,
            values: props.sales.map(row => parseInt(row.total_lots))
        }
    ];

    const salesChartLabels = props.sales.map(row => row.d);
    const salesChartDatasets = [
        {
            title: 'SALES',
            color: props.color,
            values: props.sales.map(row => (row.total_sales * 1))
        }
    ];

    const cogsChartLabels = props.sales.map(row => row.d);
    const cogsChartDatasets = [
        {
            title: 'COGS',
            color: props.color,
            values: props.sales.map(row => (row.total_cogs * 1))
        }
    ];

    const returnsChartLabels = props.returns.map(row => row.d);
    const returnsChartDatasets = [
        {
            title: 'ITEMS',
            color: props.color,
            values: props.returns.map(row => parseInt(row.total))
        }
    ];

    const transfersChartLabels = props.transfers.map(row => row.d);
    const transfersChartDatasets = [
        {
            title: 'PAID',
            color: props.color,
            values: props.transfers.map(row => parseInt(row.total_items) - parseInt(row.total_member))
        },
        {
            title: 'MEMBER',
            color: '#CCCCCC',
            values: props.transfers.map(row => parseInt(row.total_member))
        }
    ];

    const trucksChartLabels = props.trucks.map(row => row.long_id);
    const trucksChartDatasets = [
        {
            title: 'COST',
            color: '#CCCCCC',
            values: props.trucks.map(row => (row.total_cogs * 1))
        },
        {
            title: 'PROFIT',
            color: props.color,
            values: props.trucks.map(row => (row.total_sales * 1) - (row.total_cogs * 1))
        }
    ];

    return (
        <div style={{padding:"20px"}}>
            <h1 style={{fontSize:"30px"}} className="react-rainbow-admin-header-pages_title">{props.name}</h1>
            <p style={{display:"flex",width:"50%", alignItems: "center", justifyContent:"space-between"}} >
                <strong>DAILY LOT GOAL: {props.goals.daily_lots.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong> 
                <strong>DAILY SALES GOAL: ${props.goals.daily_sales.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                <strong>AVG LOT SALE GOAL: ${(props.goals.avg_lot/1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
            </p>
            <div>
                <section style={{padding:"16px 0px"}} className="react-rainbow-admin-orders_cards-container">
                    <LocationDashboardBarChart title="Scans" badge={(totalScans / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labels={scanChartLabels} datasets={scanChartDatasets}></LocationDashboardBarChart>
                    <LocationDashboardBarChart title="Lots Closed" badge={(totalLots / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labels={lotsChartLabels} datasets={lotsChartDatasets}></LocationDashboardBarChart>
                </section>
                <section style={{padding:"16px 0px"}} className="react-rainbow-admin-orders_cards-container">
                    <LocationDashboardBarChart title="Sales" badge={'$' + (totalSales / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labels={salesChartLabels} datasets={salesChartDatasets}></LocationDashboardBarChart>
                    <LocationDashboardBarChart title="Cost of Goods" badge={'$' + (totalCogs / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labels={cogsChartLabels} datasets={cogsChartDatasets}></LocationDashboardBarChart>
                </section>
            </div>
            <div style={{display:"flex", margin:"10px 0px"}}>
                <LocationDashboardStat stat="Auction Scans / Day" value={((totalScans - totalPalletScans) / props.workdays)} append={' (' + ((totalScans - totalPalletScans) / totalScans * 100).toFixed(1) + '%)' }></LocationDashboardStat>
                <LocationDashboardStat stat="Percent Auction Scans" value={((totalScans - totalPalletScans) / totalScans * 100)} decimal={1} append={'%'}></LocationDashboardStat>
                <LocationDashboardStat stat="Lots Closed / Day" value={(totalLots / props.workdays)} compare={props.goals.daily_lots} decimal={0}></LocationDashboardStat>
                <LocationDashboardStat stat="Sales / Day" value={(totalSales / props.workdays)} compare={props.goals.daily_sales} decimal={0} currency={'$'}></LocationDashboardStat>
            </div>
            <div style={{display:"flex", margin:"0px 0px"}}>
                <LocationDashboardStat stat="Gross Profit" value={(totalSales - totalCogs)} currency={'$'}></LocationDashboardStat>
                <LocationDashboardStat stat="Sale Price / Lot" value={(totalSales / totalLots)} currency={'$'} compare={(props.goals.avg_lot/1)} decimal={2}></LocationDashboardStat>
                <LocationDashboardStat stat="Recovery Rate" value={(totalRetailValueSales / totalRetailValue * 100 / 2)} decimal={1} append={'%'}></LocationDashboardStat>
            </div>
            <div>
                <section style={{padding:"16px 0px"}} className="react-rainbow-admin-orders_cards-container">
                    <LocationDashboardBarChart title="Returns" badge={(totalReturns / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labels={returnsChartLabels} datasets={returnsChartDatasets}></LocationDashboardBarChart>
                    <LocationDashboardBarChart title="Transfers Out" badge={(totalTransfers / 1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' Items'} labels={transfersChartLabels} datasets={transfersChartDatasets}></LocationDashboardBarChart>
                </section>
            </div>
            <div>
                <section style={{padding:"16px 0px"}} className="react-rainbow-admin-orders_cards-container">
                    <LocationDashboardBarChart full={true} title="Trucks" badge={props.trucks.length} labels={trucksChartLabels} datasets={trucksChartDatasets}></LocationDashboardBarChart>
                    
                </section>
            </div>
        </div>
    );
}
export default LocationDashboard;