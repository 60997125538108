/* eslint-disable no-console */
import {
    resolveReturns, resolveReturnsCharts
} from '../../services/returns';

export const START_LOADING_RETURNS = 'START_LOADING_RETURNS';
export const STOP_LOADING_RETURNS = 'STOP_LOADING_RETURNS';
export const LOAD_RETURNS_DATA = 'LOAD_RETURNS_DATA';


export default function fetchReturnsData(startTime=null, endTime=null) {
    return (dispatch, getState) => {
        const { isFirstTime, start, end } = getState().returns;
        if (isFirstTime || start !== startTime || end !== endTime) {
            const promises = [
                resolveReturns(startTime, endTime)
            ];
            dispatch({ type: START_LOADING_RETURNS });
            return Promise.all(promises)
                .then((results) => {
                    const [returns] = results;
                    let chartsData = resolveReturnsCharts(returns);
                    dispatch({
                        type: LOAD_RETURNS_DATA,
                        returns,
                        chartsData,
                        startTime,
                        endTime
                    });
                    dispatch({ type: STOP_LOADING_RETURNS });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_RETURNS });
                });
        }
        return null;
    };
}

