const APIHOST = (process.env.NODE_ENV === "development1") ? "http://localhost:9006/api" : "https://recommerce-pist2.herokuapp.com/api";


function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}

export function getAuctionLocations(start=null, end=null, sourceId=null, includeSpecials=true, includePallets=true) {
    console.log('GETTING AUCTION LOCATIONS...');
    let url = APIHOST + "/auctionlocations";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + includeSpecials + "/" + includePallets;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getAuctionData(start=null, end=null, sourceId=null, includeSpecials=true, includePallets=true) {
    console.log('GETTING AUCTION DATA...');
    let url = APIHOST + "/auctiondata";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + includeSpecials + "/" + includePallets;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getRetailBreakdownData(start=null, end=null, sourceId=null, includeSpecials=true, includePallets=true) {
    console.log('GETTING RETAIL BREAKDOWN DATA...');
    let url = APIHOST + "/retailbreakdown";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + sourceId + "/" + includeSpecials + "/" + includePallets;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}
