import * as apiClient from '../../services/api-client';

export const getAuthToken = async (store, loginData) => {
    const auth = await apiClient.auth.login(loginData);
    console.log('GOT AUTH', auth);
    if(auth.error) {
        const error = auth.error;
        store.setState({ error });
        return;
    }
    const error = null;
    store.setState({ error });
    store.setState({ auth });
};