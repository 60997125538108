

export default function resolveReturnsCharts(returnsData) {
    console.log('BUILD RETURNS CHARTS...', returnsData);

    let data = {
        reasons: {
            title: 'Reasons',
            labels: [],
            value: []
        },
        authorizers: {
            title: 'Authorizers',
            labels: [],
            value: []
        },
        scanners: {
            title: 'Scanners',
            labels: [],
            value: []
        },
        locations: {
            title: 'Locations',
            labels: [],
            value: []
        },
        sources: {
            title: 'Sources',
            labels: [],
            value: []
        }
    };


    returnsData.forEach(item => {
        if(data.reasons.labels.indexOf(item.reason) < 0) {
            data.reasons.labels.push(item.reason);
            data.reasons.value.push(1); 
        } else {
            let idx = data.reasons.labels.indexOf(item.reason);
            data.reasons.value[idx]++;
        }


        if(data.scanners.labels.indexOf(item.scanned_by) < 0) {
            data.scanners.labels.push(item.scanned_by);
            data.scanners.value.push(1); 
        } else {
            let idx = data.scanners.labels.indexOf(item.scanned_by);
            data.scanners.value[idx]++;
        }

        if(data.authorizers.labels.indexOf(item.authorized_by) < 0) {
            data.authorizers.labels.push(item.authorized_by);
            data.authorizers.value.push(1); 
        } else {
            let idx = data.authorizers.labels.indexOf(item.authorized_by);
            data.authorizers.value[idx]++;
        }

        if(data.locations.labels.indexOf(item.location) < 0) {
            data.locations.labels.push(item.location);
            data.locations.value.push(1); 
        } else {
            let idx = data.locations.labels.indexOf(item.location);
            data.locations.value[idx]++;
        }

        if(data.sources.labels.indexOf(item.truck_source) < 0) {
            data.sources.labels.push(item.truck_source);
            data.sources.value.push(1); 
        } else {
            let idx = data.sources.labels.indexOf(item.truck_source);
            data.sources.value[idx]++;
        }

    });

    var threshold = 0.05; //10%
    var otherValue = 0;
    var newValues = [];
    var newLabels = [];
    for(var i = 0; i < data.scanners.value.length; i ++) {
        if( (data.scanners.value[i] / returnsData.length) < threshold) {
            otherValue += data.scanners.value[i];
        } else {
            newValues.push(data.scanners.value[i]);
            newLabels.push(data.scanners.labels[i]);
        }
    }
    if(otherValue > 0) {
        newLabels.push('other');
        newValues.push(otherValue);
    }
    data.scanners.value = newValues;
    data.scanners.labels = newLabels;

    threshold = 0.08;
    otherValue = 0;
    newValues = [];
    newLabels = [];
    for(var i = 0; i < data.authorizers.value.length; i ++) {
        if( (data.authorizers.value[i] / returnsData.length) < threshold) {
            otherValue += data.authorizers.value[i];
        } else {
            newValues.push(data.authorizers.value[i]);
            newLabels.push(data.authorizers.labels[i]);
        }
    }
    if(otherValue > 0) {
        newLabels.push('other');
        newValues.push(otherValue);
    }
    data.authorizers.value = newValues;
    data.authorizers.labels = newLabels;
    
    
    return data;
}
