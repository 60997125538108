import React from 'react';
import PropTypes from 'prop-types';

export default function Pages(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18">
            <g fill="none" fillRule="evenodd">
                <path fill="#B3B5BD" d="M15.152 17.45c-.261-.172-1.758-.737-1.758-.737h-.008l-.394-3.157H9.085l-.394 3.156h-.007s-1.498.566-1.76.738l-.221.34c-.092.06.002.162.147.162H15.227c.146 0 .239-.102.147-.163l-.222-.339z" />
                <path fill="#CED0D9" d="M20.952.008H1.074C.481.008 0 .513 0 1.137v12.41c0 .623.481 1.128 1.074 1.128h19.878c.593 0 1.074-.505 1.074-1.128V1.137c0-.624-.48-1.129-1.074-1.129" />
                <path fill="#576574" d="M7.457 13.462h7.304a6.18 6.18 0 1 0 0-12.358H7.457a6.18 6.18 0 1 0 0 12.358z" />
                <g>
                    <path fill="#FFF" fillRule="nonzero" d="M1.18 13.268c0 .196.158.354.353.354h19.113a.354.354 0 0 0 .354-.354V3.012H1.18v10.256z" />
                    <path fill="#1DE9B6" fillRule="nonzero" d="M20.646.89H1.533a.354.354 0 0 0-.353.354v1.768H21V1.244a.354.354 0 0 0-.354-.354z" />
                    <path fill="#0DC596" d="M5.07 1.597H2.24a.354.354 0 0 0 0 .708h2.83a.354.354 0 0 0 0-.708z" />
                    <g fill="#FFF" fillOpacity=".273">
                        <path d="M7.9 1.602H6.484c-.196 0-.354.209-.354.465 0 .257.158.465.354.465h1.414c.196 0 .354-.208.354-.465 0-.256-.158-.465-.354-.465zM10.729 1.602H9.314c-.195 0-.354.209-.354.465 0 .257.159.465.354.465h1.415c.195 0 .353-.208.353-.465 0-.256-.158-.465-.353-.465zM13.558 1.602h-1.415c-.195 0-.353.209-.353.465 0 .257.158.465.353.465h1.415c.195 0 .354-.208.354-.465 0-.256-.159-.465-.354-.465zM16.387 1.602h-1.414c-.196 0-.354.209-.354.465 0 .257.158.465.354.465h1.414c.196 0 .354-.208.354-.465 0-.256-.158-.465-.354-.465zM19.217 1.602h-1.415c-.195 0-.354.209-.354.465 0 .257.159.465.354.465h1.415c.195 0 .353-.208.353-.465 0-.256-.158-.465-.353-.465z" />
                    </g>
                    <g fill="#E3E5ED" fillOpacity=".658">
                        <path d="M3.094 7.963h4.598a.354.354 0 0 0 .354-.353V4.78a.354.354 0 0 1-.354.354H3.094a.354.354 0 0 1-.353-.354v2.83c0 .195.158.353.353.353zM9.46 7.963h2.122a.354.354 0 0 0 .354-.353V4.78a.354.354 0 0 1-.354.354H9.46a.354.354 0 0 1-.353-.354v2.83c0 .195.158.353.353.353zM13.35 7.963h2.123a.354.354 0 0 0 .353-.353V4.78a.354.354 0 0 1-.353.354H13.35a.354.354 0 0 1-.354-.354v2.83c0 .195.158.353.354.353zM17.241 7.963h2.122a.354.354 0 0 0 .354-.353V4.78a.354.354 0 0 1-.354.354h-2.122a.354.354 0 0 1-.354-.354v2.83c0 .195.159.353.354.353z" />
                    </g>
                    <path fill="#FFB900" d="M3.094 5.134h4.598a.354.354 0 0 0 .354-.354v-.353a.354.354 0 0 0-.354-.354H3.094a.354.354 0 0 0-.353.354v.353c0 .196.158.354.353.354z" />
                    <path fill="#1AD1A3" d="M9.46 5.134h2.122a.354.354 0 0 0 .354-.354v-.353a.354.354 0 0 0-.354-.354H9.46a.354.354 0 0 0-.353.354v.353c0 .196.158.354.353.354z" />
                    <path fill="#FF6837" d="M13.35 5.134h2.123a.354.354 0 0 0 .353-.354v-.353a.354.354 0 0 0-.353-.354H13.35a.354.354 0 0 0-.354.354v.353c0 .196.158.354.354.354z" />
                    <path fill="#01B6F5" d="M17.241 5.134h2.122a.354.354 0 0 0 .354-.354v-.353a.354.354 0 0 0-.354-.354h-2.122a.354.354 0 0 0-.354.354v.353c0 .196.159.354.354.354z" />
                    <g fill="#ECEEF3">
                        <path d="M3.802 9.378v2.83a.354.354 0 0 1-.354.353h8.134a.354.354 0 0 0 .354-.354V9.378a.354.354 0 0 0-.354-.354H3.448c.195 0 .354.159.354.354zM14.058 9.378v2.83a.354.354 0 0 1-.354.353h5.659a.354.354 0 0 0 .354-.354V9.378a.354.354 0 0 0-.354-.354h-5.659c.196 0 .354.159.354.354z" />
                    </g>
                    <path fill="#5C56B6" d="M3.094 12.561h.354a.354.354 0 0 0 .354-.354v-2.83a.354.354 0 0 0-.354-.353h-.354a.354.354 0 0 0-.353.354v2.83c0 .195.158.353.353.353z" />
                    <path fill="#1DE9B6" d="M13.35 12.561h.354a.354.354 0 0 0 .354-.354v-2.83a.354.354 0 0 0-.354-.353h-.353a.354.354 0 0 0-.354.354v2.83c0 .195.158.353.354.353z" />
                </g>
            </g>
        </svg>
    );
}

Pages.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Pages.defaultProps = {
    className: undefined,
    style: undefined,
};
