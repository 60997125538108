import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Select, CheckboxToggle } from 'react-rainbow-components';
import useGlobal from '../../../store';
import Tile from '../../../components/Tile';
import { Badge, Card, Chart, Dataset } from 'react-rainbow-components';

const ChartsSection = (props) => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedSource, setSelectedSource] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(0);
    const [sourceList, setSourceList] = useState([]);
    const [buildingList,] = useState([
        { value: 0, label: 'All Locations'},
        { value: 1, label: 'Washington'},
        { value: 5, label: 'Washington - Big Box'},
        { value: 20, label: 'Uniontown'},
        { value: 14, label: 'Bridgeport'},
        { value: 2, label: 'Butler'},
        { value: 3, label: 'McKees Rocks'},
        { value: 16, label: 'Monroeville'},
        { value: 15, label: 'Robinson'},
        { value: 4, label: 'Beaver Falls'},
        { value: 6, label: 'Pittsburgh Mills'},
        { value: 8, label: 'Pittsburgh Mills - Big Box'},
        { value: 10, label: 'Boardman'},
        { value: 12, label: 'Akron'},
        { value: 11, label: 'Warren'},
        { value: 22, label: 'Canton'},
        { value: 9, label: 'Spartanburg'},
        { value: 13, label: 'Greenville'},
        { value: 17, label: 'Rock Hill'},
        { value: 18, label: 'Gastonia'},
        { value: 19, label: 'Anderson'}
    ]);
    const [showRetail, setShowRetail] = useState(false);
    const [includeOpen, setIncludeOpen] = useState(true);

    const [chartLabels, setChartLabels] = useState([]);
    const [chartCostData, setChartCostData] = useState([]);
    const [chartRevenueData, setChartRevenueData] = useState([]);
    const [chartProfitData, setChartProfitData] = useState([]);
    const [chartRetailData, setChartRetailData] = useState([]);
    const [chartRecoveryData, setChartRecoveryData] = useState([]);
    const [chartCostRateData, setChartCostRateData] = useState([]);

    const [totalTrucks, setTotalTrucks] = useState(0);
    const [avgTruckCost, setAvgTruckCost] = useState(0);
    const [avgTruckProfit, setAvgTruckProfit] = useState(0);
    const [totalTruckCost, setTotalTruckCost] = useState(0);
    const [totalTruckRevenue, setTotalTruckRevenue] = useState(0);
    const [totalTruckProfit, setTotalTruckProfit] = useState(0);
    const [avgTruckRevenue, setAvgTruckRevenue] = useState(0);
    const [totalROI, setTotalROI] = useState(0);
    const [totalLots, setTotalLots] = useState(0);
    const [avgLotCost, setAvgLotCost] = useState(0);
    const [avgTruckRetail, setAvgTruckRetail] = useState(0);
    const [avgRecovery, setAvgRecovery] = useState(0);

    const [truckSourceList, truckActions] = useGlobal(
        state => state.truckSourceList,
        actions => actions.truckActions
    );

    const [truckSources] = useGlobal(
        state => state.truckSources
    );

    const [truckData] = useGlobal(
        state => state.truckData
    );

    useEffect(() => {
        if(!truckSources) {
            return;
        }
        let tTrucks = truckSources.reduce((pv, cv) => pv + (cv.total_trucks * 1), 0);
        setTotalTrucks(tTrucks);
        let tCost= truckSources.reduce((pv, cv) => pv + (cv.total_cost * 1), 0);
        setTotalTruckCost('$' + tCost.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tRevenue = truckSources.reduce((pv, cv) => pv + (cv.total_revenue * 1), 0);
        setTotalTruckRevenue('$' + tRevenue.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tLots = truckSources.reduce((pv, cv) => pv + (cv.total_lots * 1), 0);
        setTotalLots(tLots.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tProfit= truckSources.reduce((pv, cv) => pv + ((cv.total_revenue * 1) - (cv.total_cost * 1)), 0);
        setTotalTruckProfit('$' + tProfit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        let tRetail= truckSources.reduce((pv, cv) => pv + (cv.total_retail * 1), 0);

        let tROI = ((tRevenue - tCost) / tCost * 100).toFixed(2) + '%'
        setTotalROI(tROI);

        let avg = '$' + (tCost / tTrucks).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgTruckCost(avg);
        avg = '$' + (tRevenue / tTrucks).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgTruckRevenue(avg);
        avg = '$' + (tCost / tLots).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgLotCost(avg);

        avg = '$' + ((tRevenue / tTrucks) - (tCost / tTrucks)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgTruckProfit(avg);

        avg = '$' + (tRetail / tTrucks).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgTruckRetail(avg);

        let recoveryRate = (tRevenue / tRetail * 100).toFixed(2) + '%';
        setAvgRecovery(recoveryRate);


    }, [truckSources]);

    useEffect(() => {
        truckActions.getTruckSourceList();

        let n = Date.now();
        let e = formatDate(n - (14 * 24 * 60 * 60 * 1000));
        let s = formatDate(n - (45 * 24 * 60 * 60 * 1000));
        truckActions.getTruckSources(s, e);
        truckActions.getTruckData(s, e);
        truckActions.getTruckSourceLocations(s, e);
    }, [truckActions]);

    const getStartOfWeek = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day; 
        
        let sunday = new Date(d.setDate(diff))
        return sunday.getFullYear() + '-' + (sunday.getMonth()+1) + '-' + sunday.getDate();
    }

    useEffect(() => {
        if(!truckData) {
            return;
        }
        let data = {
            days: [],
            trucks: [],
            costs: [],
            revenues: [],
            profits: [],
            retails: [],
            recoveryRates: [],
            costRates: []
        };
        for(let i = 0; i < truckData.length; i++) {
            let dtRec = truckData[i].date_received.split(" ")[0];
            dtRec = getStartOfWeek(dtRec);
            let dtIdx = data.days.indexOf(dtRec);
            if(dtIdx < 0) {
                data.days.push(dtRec);
                data.trucks.push(1);
                data.costs.push(truckData[i].cost * 1);
                data.revenues.push(truckData[i].total_sales * 1);
                data.profits.push( (truckData[i].total_sales * 1) - (truckData[i].cost * 1) );
                data.retails.push(truckData[i].total_item_retail_value * 1);
            } else {
                data.trucks[dtIdx]++;
                data.costs[dtIdx] = data.costs[dtIdx] + (truckData[i].cost * 1);
                data.revenues[dtIdx] = data.revenues[dtIdx] + (truckData[i].total_sales * 1);
                data.profits[dtIdx] = data.profits[dtIdx] + (truckData[i].total_sales * 1) - (truckData[i].cost * 1);
                data.retails[dtIdx] = data.retails[dtIdx] + (truckData[i].total_item_retail_value * 1);
            }
        }
        for(let j=0; j < data.days.length; j++) {
            data.costs[j] = parseInt(data.costs[j] / data.trucks[j], 10);
            data.revenues[j] = parseInt(data.revenues[j] / data.trucks[j], 10);
            data.profits[j] = parseInt(data.profits[j] / data.trucks[j], 10);
            data.retails[j] = parseInt(data.retails[j] / data.trucks[j], 10);
            data.recoveryRates.push(data.revenues[j] / data.retails[j] * 100);
            data.costRates.push(data.costs[j] / data.retails[j] * 100);
        }

        setChartLabels(data.days.reverse());
        setChartCostData(data.costs.reverse());
        setChartRevenueData(data.revenues.reverse());
        setChartProfitData(data.profits.reverse());
        setChartRetailData(data.retails.reverse());
        setChartRecoveryData(data.recoveryRates.reverse());
        setChartCostRateData(data.costRates.reverse());
    }, [truckData]);
    

    const handleClick = () => {
        console.log('click', startDate, endDate, selectedSource, selectedLocation, includeOpen);
        let s = formatDate(startDate);
        let e = formatDate(endDate);
        let includeOpenParam = includeOpen ? 1 : 0;
        truckActions.getTruckSources(s, e, selectedSource, selectedLocation, includeOpenParam);
        truckActions.getTruckSourceLocations(s, e, selectedSource, selectedLocation, includeOpenParam);
        truckActions.getTruckData(s, e, selectedSource, selectedLocation, includeOpenParam);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        const beginning = props.match.params.start ?
            new Date(new Date(props.match.params.start).getTime() + 5 * 60 * 60 * 1000)
            : new Date(new Date().getTime() - (45 * 24 * 60 * 60 * 1000));
        const ending = props.match.params.end ?
            new Date(new Date(props.match.params.end).getTime() + 5 * 60 * 60 * 1000) 
            : new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000));
        setStartDate(beginning);
        setEndDate(ending);
    }, []);

    const containerStyles = {
        width: 300,
    };

    const handleSelect = (e) => {
        console.log(e.target, e.target.value);
        setSelectedSource(e.target.value);
    }
    const handleSelectLocation = (e) => {
        console.log(e.target, e.target.value);
        setSelectedLocation(e.target.value);
    }

    useEffect(() => {
        if(!truckSourceList) {
            return;
        }
        let options = [
            { value: '0', label: 'All Sources' }
        ];
        for(const s in truckSourceList) {
            options.push({ value: truckSourceList[s].id, label: truckSourceList[s].name + ' (' +truckSourceList[s].abbreviation + ')'  });
        }
        setSourceList(options);
    }, [truckSourceList]);

    const toggleRetail = ()=> {
        console.log('toggle Retail');
        setShowRetail(!showRetail);
    }

    return (<>
    <section className="react-rainbow-admin-orders_cards-container">
        <div style={{display:"flex", alignItems:"center"}}>
                <div className="date-range">
                    <DatePicker
                        id="datePicker1"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                        label="Start Date (Received)"
                        formatStyle="medium"
                    />
                    <DatePicker
                        id="datePicker2"
                        value={endDate}
                        onChange={value => setEndDate(value)}
                        label="End Date (Received)"
                        formatStyle="medium"
                    />
                    
                </div>
                
                <Select
                        label="Select Source"
                        options={sourceList}
                        style={containerStyles}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        onChange={(e) => handleSelect(e)}
                    />
                <Select
                        label="Select Location"
                        options={buildingList}
                        style={containerStyles}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        onChange={(e) => handleSelectLocation(e)}
                    />
                <CheckboxToggle
                    id="checkbox-toggle-open"
                    label="Include Open"
                    value={includeOpen}
                    onChange={(e) => setIncludeOpen(!includeOpen)}
                />
                    <Button label="GO" onClick={() => handleClick()} variant="neutral" className="rainbow-m-around_medium" />
            </div>
    </section>
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Total Trucks"
                label={totalTrucks}
                 />
        <Tile
                title="Total Cost"
                label={totalTruckCost}
                 />
        <Tile
                title="Total Revenue"
                label={totalTruckRevenue}
                 />
        <Tile
                title="Total Profit"
                label={totalTruckProfit}
                 />
    
    </section>
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Avg Truck Cost"
                label={avgTruckCost}
                 />
        <Tile
                title="Avg Truck Revenue"
                label={avgTruckRevenue}
                 />
        <Tile
                title="Avg Truck Profit"
                label={avgTruckProfit}
                 />
        <Tile
                title="ROI"
                label={totalROI}
                 />
        
    
    </section>
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Total Lots"
                label={totalLots}
                 />
        <Tile
                title="Avg Lot Cost"
                label={avgLotCost}
                 />
        <Tile
                title="Avg Retail"
                label={avgTruckRetail}
                 />
        <Tile
                title="Recovery"
                label={avgRecovery}
                 />
        
    
    </section>
    {truckData && chartRevenueData ? 
    <section className="react-rainbow-admin-orders_cards-container">
        <Card
            className="react-rainbow-admin-full_card"
            title="Weekly Averages"
            >
            <h1 onClick={()=>toggleRetail()} className="react-rainbow-admin-users_card-title">Click to Toggle AVG RETAIL</h1>
            <div className="react-rainbow-admin-full_chart">
                <Chart
                    labels={chartLabels}
                    type="line"
                    showLegend={false}
                    maintainAspectRatio={false}>
                    {showRetail ? <Dataset title="Average Retail" values={chartRetailData} borderColor="#CCCCCC" backgroundColor="#CCCCCC" /> : <></> }
                    <Dataset title="Average Cost" values={chartCostData} borderColor="#fe4849" backgroundColor="#fe4849" />
                    <Dataset title="Average Revenue" values={chartRevenueData} borderColor="#01b6f5" backgroundColor="#01b6f5" />
                    <Dataset title="Average Profit" values={chartProfitData} borderColor="#8ef4da" backgroundColor="#8ef4da" />
                </Chart>
            </div>
        </Card>
    </section> : <></> }
    {truckData && chartRevenueData ? 
    <section className="react-rainbow-admin-orders_cards-container">
        <Card
            className="react-rainbow-admin-full_card"
            title="Weekly Recovery & Cost Ratios"
            >
            <h1 className="react-rainbow-admin-users_card-title">BY TRUCK DATE RECEIVED</h1>
            <div className="react-rainbow-admin-full_chart">
                <Chart
                    labels={chartLabels}
                    type="line"
                    showLegend={false}
                    maintainAspectRatio={false}>
                    <Dataset title="Revenue Over Retail" values={chartRecoveryData} borderColor="#01b6f5" backgroundColor="#01b6f5" />
                    <Dataset title="Cost Over Retail" values={chartCostRateData} borderColor="#fe4849" backgroundColor="#fe4849" />
                </Chart>
            </div>
        </Card>
    </section> : <></> }
</>);

}
export default ChartsSection;