import React, { useEffect, useState } from 'react';
import * as FirestoreService from '../../services/firestore';
import LiveAuctions from './LiveAuctions';

const AuctionsPage = (props) => {

    const [firebaseUser, setFirebaseUser] = useState(null);
    const [lots, setLots] = useState([]);

    const updateLots = (updatedData) => {
        let newLots = [
            ...lots,
            ...updatedData
        ];
        setLots(newLots);
    }
    useEffect(() => {
        if(!firebaseUser) {
            console.log('NOT AUTHENTICATED');
            return;
        }
        const unsubscribe = FirestoreService.streamAuctionLots({
            next: querySnapshot => {
                let updatedAuctionLots = [];
                querySnapshot.forEach(function(doc) {
                    updatedAuctionLots.push({
                        auction: doc.id.split("-")[0],
                        ...doc.data()
                    });
                });
                console.log('NEW AUCTION LOT DATA', updatedAuctionLots);
                updateLots(updatedAuctionLots);
            },
            error: () => console.log('auction-lots-get-fail')
        });
        return unsubscribe;  
      }, [firebaseUser]);

    useEffect(() => {
    
        FirestoreService.getToken().then((token) => {
            console.log('TOOOOOKEN', token);
            FirestoreService.auth(token.token).then((userCredential) => {
                console.log('signed in firebase...', userCredential.user);
                setFirebaseUser(userCredential.user);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
        });
        
    
    }, []);

    return (<>
        <LiveAuctions lots={lots}></LiveAuctions>
    </>);
}

export default AuctionsPage;