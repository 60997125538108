import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-rainbow-components';
import ChartsSection from './chartsSection';
import AuctionDetailTabs from './auctionDetailTabs';
import Tile from '../../../../components/Tile';
import { ShoppingCartIcon, PricingIcon, SalesIcon } from '../../../../components/icons';


export default function ContentPage(props) {
    const {
        lots,
        chartsData,
        isLoading,
    } = props;

    if (isLoading) {
        return <Spinner size="large" />;
    }
    console.log('CONTENT PAGE!!', props, chartsData);
    return (
        <div>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                
               
            </section>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                
               
            </section>
            <ChartsSection lots={lots} />
            <AuctionDetailTabs lots={lots} />
        </div>
    );
}

ContentPage.propTypes = {
    lots: PropTypes.array.isRequired,
    chartsData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
