import React, { useEffect } from 'react';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import ContentPage from './contentPage';
import { navigateTo } from '../../../history';

const TruckSources = (props) => {

    useEffect(() => {
        
    }, []);

    return (<div>
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs>
                <Breadcrumb label="Reports" onClick={() => navigateTo('/reports')} />
                <Breadcrumb label="Truck Sources" />
            </Breadcrumbs>
        </div>
        <ContentPage {...props} />
    </div>)
}

export default TruckSources;