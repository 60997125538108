import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-rainbow-components';
import {
    CreditCard,
    FollowerIcon,
    OrderIcon,
    UsersIcon,
    TimelineIcon,
    GalleryIcon,
    PricingIcon,
    SalesIcon,
    PersonIcon,
    SignPost,
    Map,
} from '../../components/icons';
import PageHeader from '../../components/PageHeader';
import './styles.css';

const pages = [
    { name: 'Inventory', icon: <GalleryIcon />, href: '/reports/inventory' },
    { name: 'Trucks', icon: <GalleryIcon />, href: '/reports/trucksources' },
    { name: 'Customers', icon: <UsersIcon />, href: '/reports/customers' },
    { name: 'Auctions', icon: <PricingIcon />, href: '/reports/auctions' },
    { name: 'Transfers', icon: <TimelineIcon />, href: '/reports/transfers' },
    { name: 'Sales', icon: <OrderIcon />, href: '/reports/sales' },
    { name: 'Invoices', icon: <PricingIcon />, href: '/reports/invoices' },
    { name: 'Returns', icon: <PricingIcon />, href: '/reports/returns' },
    /* { name: 'Calendar', icon: <CalendarIcon />, href: '/comingSoon' },*/
   
];

function Cards() {
    return pages.map((page, index) => {
        const { name, icon, href } = page;
        const key = `card-${index}`;
        return (
            <Link key={key} className="react-rainbow-admin-pages_anchor" to={href}>
                <Card className="react-rainbow-admin-pages_card" footer={name}>
                    <span className="react-rainbow-admin-pages_card-image">
                        {icon}
                    </span>
                </Card>
            </Link>
        );
    });
}
export default function Reports() {
    return (
        <div className="react-rainbow-admin-pages_container">
            <PageHeader
                title="Reports"
                description="Various business reports." />
            <div className="react-rainbow-admin-pages_body">
                <Cards />
            </div>
        </div>
    );
}
