import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Tab, Table, Tabset,
} from 'react-rainbow-components';
import TablePagination from '../../../components/TablePagination';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import CurrencyCell from '../../components/currencyCell';
import DateCell from '../../components/dateCell';
import { CSVLink, CSVDownload } from "react-csv";


export default class SalesTabs extends Component {
    constructor(props) {
        console.log('SALES TABS PROPS', props);
        super(props);
        this.state = {
            activeTabName: 'sales',
            activePage: 1,
        };
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    getCsvData() {
        const { sales } = this.props;
        //let items = sales.reverse();
        
        const csvData = [["inventoryID", "productName", "location", "dateSold", "bidPrice", "revenue", "cost", "truck", "truckSource"]].concat(sales.map(i => {
            return [
                i.id,
                i.product_name.replace(/"/g, '&quot;'),
                i.location,
                i.date_sold,
                i.bid_price,
                i.revenue,
                i.cost,
                i.truck,
                i.truck_source
            ];
        }));
        return csvData;
    }
    getTableData() {
        const { sales } = this.props;
        const { activeTabName, activePage } = this.state;
        const firstItem = (activePage - 1) * 200;
        const lastItem = firstItem + 200;

        return sales.slice(firstItem, lastItem);
    }

    getPages() {
        const { sales } = this.props;
        const { activeTabName } = this.state;
        return Math.ceil(sales.length / 200);
    }

    handleOnSelect(event, tab) {
        this.setState({ activeTabName: tab, activePage: 1 });
    }

    handleOnChange(event, page) {
        this.setState({ activePage: page });
    }

    render() {
        const { activeTabName, activePage } = this.state;
        return (
            <div>
                <Tabset
                    onSelect={this.handleOnSelect}
                    className="react-rainbow-admin-users_tab-set"
                    activeTabName={activeTabName}>
                    <Tab
                        label="SOLD ITEMS"
                        name="sales" />
                    <Tab
                        label="SEARCH"
                        name="search" />

                </Tabset>
                <div className="react-rainbow-admin-users_tab-content">
                    <RenderIf isTrue={activeTabName !== 'search'}>
                        <CSVLink className="btn btn-primary download-link" data={this.getCsvData()}>Download Report</CSVLink>
                        <Table data={this.getTableData()} keyField="id">
                            <Column header="ID" sortable field="id" defaultWidth={100} />
                            <Column header="DATE SOLD" field="date_sold" component={DateCell} defaultWidth={100}/>  
                            <Column header="NAME" field="product_name" />
                            <Column header="LOCATION" field="location" defaultWidth={100}/>
                            <Column header="BID PRICE" field="bid_price" component={CurrencyCell} sortable={true} defaultWidth={130} />
                            <Column header="REVENUE" field="revenue" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="COST" field="cost" component={CurrencyCell} sortable={true} defaultWidth={100} />
                            <Column header="TRUCK" field="truck" sortable={true} defaultWidth={100} />
                            <Column header="SOURCE" field="truck_source" sortable={true} defaultWidth={100} />
                        </Table>
                        <TablePagination
                            pages={this.getPages()}
                            activePage={activePage}
                            onChange={this.handleOnChange} />
                    </RenderIf>
       
                </div>
            </div>
        );
    }
}

SalesTabs.propTypes = {
    sales: PropTypes.array.isRequired,
};
