import React, {  } from 'react';
import AuctionsTabs from './auctionsTabs';
import ChartsSection from './chartsSection';

const ContentPage = (props) => {

    const chartsData = [];
    const sales = [];

    return (<div>
        <ChartsSection {...props} chartsData={chartsData} />
        <AuctionsTabs sales={sales} />
    </div>);

}
export default ContentPage;