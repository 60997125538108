import * as apiClient from '../../services/api-client';

export const setKpis = async (store, kpis) => {
    console.log('SETTING KPIS', kpis);
    store.setState({ kpis });
};
export const getKpis = async (store, start=null, end=null, locationId=0) => {
    const kpis = await apiClient.kpis.getKpis(start, end, locationId);
    console.log('GOT KPIS', kpis);
    store.setState({ kpis });
};

export const getRecentScans = async (store, hours=12) => {
    const recentScans = await apiClient.kpis.getHourlyScans(hours);
    console.log('GOT RECENT SCANS', recentScans);
    store.setState({ recentScans });
};

export const getDailyScans = async (store, start=null, end=null) => {
    const dailyScans = await apiClient.kpis.getDailyScans(start, end);
    console.log('GOT DAILY SCANS', dailyScans);
    store.setState({ dailyScans });
};

export const getRecentBids = async (store, hours=12) => {
    const recentBids = await apiClient.kpis.getHourlyBids(hours);
    console.log('GOT RECENT BIDS', recentBids);
    store.setState({ recentBids });
};

export const getDailyBids = async (store, start=null, end=null) => {
    const dailyBids = await apiClient.kpis.getDailyBids(start, end);
    console.log('GOT DAILY BIDS', dailyBids);
    store.setState({ dailyBids });
};

export const getDailyRevenue = async (store, start=null, end=null) => {
    const dailyRevenue = await apiClient.kpis.getDailyRevenue(start, end);
    console.log('GOT DAILY REVENUE', dailyRevenue);
    store.setState({ dailyRevenue });
};

export const getDailyRetailValue = async (store, start=null, end=null) => {
    const dailyRetailValue = await apiClient.kpis.getDailyRetailValue(start, end);
    console.log('GOT DAILY RETAIL VALUE', dailyRetailValue);
    store.setState({ dailyRetailValue });
};

export const getDailyNewUsers = async (store, start=null, end=null) => {
    const dailyNewUsers = await apiClient.kpis.getDailyNewUsers(start, end);
    console.log('GOT DAILY NEW USERS', dailyNewUsers);
    store.setState({ dailyNewUsers });
};