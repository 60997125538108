import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Spinner } from 'react-rainbow-components';
import useGlobal from '../../store';
import LocationDashboard from './LocationDashboard';
import { navigateTo } from '../../history';


const NewDashboard = (props) => {

    const [location, setLocation] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [kpis, kpiActions] = useGlobal(
        state => state.kpis,
        actions => actions.kpiActions
    );
      
    const handleClick = () => {
        console.log('click', startDate, endDate, location);
        let s = formatDate(startDate);
        let e = formatDate(endDate);
        //navigateTo('/location/' + location + '/'+s+'/'+e);
        setIsLoading(true);
        kpiActions.getKpis(s, e, location);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        var date = new Date();
        const locationId = props.match.params.location ? props.match.params.location : 'all';

        const beginning = props.match.params.start ?
            new Date(new Date(props.match.params.start).getTime() + 5 * 60 * 60 * 1000)
            : new Date(date.getFullYear(), date.getMonth(), 1);
        const ending = props.match.params.end ?
            new Date(new Date(props.match.params.end).getTime() + 5 * 60 * 60 * 1000) 
            : new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        
        console.log('LOCATION: ', locationId);
        console.log('BEGINNING: ' , beginning);
        console.log('ENDING: ', ending);
        setLocation(locationId);
        setStartDate(beginning);
        setEndDate(ending);
        kpiActions.getKpis(formatDate(beginning), formatDate(ending), locationId);
    }, [kpiActions]);

    useEffect(() => {
        if(!kpis) {
            return;
        }
        setIsLoading(false);
        console.log('NEW KPIS!', kpis);
        setLocation(kpis.locations[0].id);
    }, [kpis]);

    return (<>
        <div style={{padding: "20px 30px"}}>
            <div style={{padding:"20px"}}>
            
                <div className="date-range">
                    <DatePicker
                        id="datePicker1"
                        value={startDate}
                        onChange={value => setStartDate(value)}
                        label="Start Date"
                        formatStyle="medium"
                    />
                    <DatePicker
                        id="datePicker2"
                        value={endDate}
                        onChange={value => setEndDate(value)}
                        label="End Date"
                        formatStyle="medium"
                    />
                    <Button label="GO" onClick={handleClick} variant="neutral" className="rainbow-m-around_medium" />
                </div>
           
            </div>
            
            { isLoading || !kpis ? <Spinner size="large" /> : <></>}
            { (kpis && !isLoading) ?
                kpis.locations.map(location => <LocationDashboard key={location.id} goals={kpis.goals} workdays={kpis.workdays} {...location}></LocationDashboard>)
                : <></>
            }
            
        </div>
    </>);
}

export default NewDashboard;