export default function resolveSources() {
    console.log('attempting to get sources');
    return fetch("https://recommerce-pist2.herokuapp.com/api/sources", {
        headers: {
            'Authorization': process.env.REACT_APP_API_KEY
        }
    })
      .then(res => {
          console.log('GOT SOURCES', res);
          return res.json();
      });
}