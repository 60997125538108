/* eslint-disable no-console */
import {
    resolveInvoices
} from '../../services/invoices';

export const START_LOADING_INVOICES = 'START_LOADING_INVOICES';
export const STOP_LOADING_INVOICES = 'STOP_LOADING_INVOICES';
export const LOAD_INVOICES_DATA = 'LOAD_INVOICES_DATA';


export default function fetchInvoicesData(startTime=null, endTime=null, findbySlug=null) {
    return (dispatch, getState) => {
        const { isFirstTime, start, end, findby } = getState().invoices;
        if (isFirstTime || start !== startTime || end !== endTime || findby !== findbySlug) {
            const promises = [
                resolveInvoices(startTime, endTime, findbySlug)
            ];
            dispatch({ type: START_LOADING_INVOICES });
            return Promise.all(promises)
                .then((results) => {
                    const [invoices] = results;
                    dispatch({
                        type: LOAD_INVOICES_DATA,
                        invoices,
                        startTime,
                        endTime,
                        findbySlug
                    });
                    dispatch({ type: STOP_LOADING_INVOICES });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_INVOICES });
                });
        }
        return null;
    };
}

