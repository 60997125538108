/* eslint-disable no-console */
import {
    resolveTrucks,
    resolveSources,
    resolveChartsData,
} from '../../services/trucks';

export const START_LOADING_TRUCKS = 'START_LOADING_TRUCKS';
export const STOP_LOADING_TRUCKS = 'STOP_LOADING_TRUCKS';
export const LOAD_TRUCKS_DATA = 'LOAD_TRUCKS_DATA';

export const START_LOADING_TRUCK_DETAILS = 'START_LOADING_TRUCK_DETAILS';
export const STOP_LOADING_TRUCK_DETAILS = 'STOP_LOADING_TRUCK_DETAILS';
export const LOAD_TRUCK_DETAILS_DATA = 'LOAD_TRUCK_DETAILS_DATA';

export default function fetchTrucksData() {
    return (dispatch, getState) => {
        const { isFirstTime } = getState().trucks;
        if (isFirstTime) {
            const promises = [
                resolveTrucks(),
                resolveChartsData(),
                resolveSources()
            ];
            dispatch({ type: START_LOADING_TRUCKS });
            return Promise.all(promises)
                .then((results) => {
                    const [trucks, chartsData, sources] = results;
                    dispatch({
                        type: LOAD_TRUCKS_DATA,
                        trucks,
                        chartsData,
                        sources
                    });
                    dispatch({ type: STOP_LOADING_TRUCKS });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: STOP_LOADING_TRUCKS });
                });
        }
        return null;
    };
}

