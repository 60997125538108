import React, { useState } from 'react';
import AuctionDashboardStat from './AuctionDashboardStat';
import AuctionLotDashboard from './AuctionLotDashboard';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AuctionDashboard = (props) => {

    const [showLotDetail, setShowLotDetail] = useState(false);

    const totalBidValue = props.lots.reduce((pv,cv) => pv + cv.current_bid, 0);
    const totalMaxBidValue = props.lots.reduce((pv,cv) => pv + cv.max_bid, 0);
    const totalBids = props.lots.reduce((pv,cv) => pv + cv.total_bids, 0);
    const totalOpenLots = props.lots.filter(l => l.is_open).length;
    const uniqueBidders = props.lots
                    .map(l => l.unique_bidders)
                    .reduce((pv, cv) => [ ...pv, ...cv], [])
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .length;
    const lastLotClose = "N/A";// props.lots.reduce((pv,cv) => pv > cv ? pv : cv, 0);
    console.log('LAST LOT CLOSE', lastLotClose);
    return (
    <div style={{padding:"20px"}}>
        <h1 className="react-rainbow-admin-header-pages_title">Auction ID: #{props.auction}</h1>
        <p className="react-rainbow-admin-header-pages_description">Last Lot Closes: {lastLotClose}</p>
        <div style={{display:"flex", margin:"10px 0px"}}>
            <AuctionDashboardStat stat="Lots" value={props.lots.length} compare={totalOpenLots + ' open'}></AuctionDashboardStat>
            <AuctionDashboardStat stat="Bids" value={totalBids}></AuctionDashboardStat>
            <AuctionDashboardStat stat="Bid Value" value={'$' + totalBidValue} compare={'$' + totalMaxBidValue + ' max'}></AuctionDashboardStat>
            <AuctionDashboardStat stat="Unique Bidders" value={uniqueBidders}></AuctionDashboardStat>
        </div>
        <div style={{background:"white", borderRadius: "4px", padding:"20px", position: "relative"}}>
            <button style={{background: "#333", color: "white", borderRadius: "40px", position: "absolute", right: "5px", top: "5px"}} onClick={(e) => setShowLotDetail(!showLotDetail)}>
                { showLotDetail ? 
                    <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown}/> }
                    
            </button>
            { showLotDetail ? <>
            <div style={{display:"flex", borderBottom: "1px solid #EEE", padding: "5px 0px", fontWeight: "bold", marginTop:"8px"}}>
                <div style={{width: "100px"}}>Lot #</div>
                <div style={{width: "350px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Title</div>
                <div style={{width: "100px", textAlign:"center"}}>Status</div>
                <div style={{width: "100px", textAlign:"center"}}>Total Bids</div>
                <div style={{width: "100px", textAlign:"center"}}>Unique Bidders</div>
                <div style={{width: "100px", textAlign:"right"}}>Current Bid</div>
                <div style={{width: "100px", textAlign:"right"}}>Max Bid</div>
                
            </div>
            <div>
                { props.lots.sort((a,b) => ((a.lot_number*1) > (b.lot_number*1)) ? 1 : -1).map(lot => <AuctionLotDashboard key={lot.lot_id} {...lot}></AuctionLotDashboard>)}
            </div>
            </> : <></> }
        </div>
    </div>
    )

}

export default AuctionDashboard;