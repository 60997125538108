import {
    STOP_LOADING_TRUCKS,
    LOAD_TRUCKS_DATA,
    START_LOADING_TRUCKS,
} from '../../actions/trucks';

const initialState = {
    trucks: [],
    sources: [],
    chartsData: {
        received: {
            labels: [],
            value: [],
        },
        avgCost: {
            labels: [],
            value: [],
        },
    },
    isLoading: true,
    isFirstTime: true,
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}

function loadData(state, action) {
    const newState = {
        ...state,
        chartsData: action.chartsData,
        trucks: action.trucks,
        sources: action.sources,
        isFirstTime: false,
    };
    console.log('load data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function trucks(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_TRUCKS:
            return startLoading(state);
        case LOAD_TRUCKS_DATA:
            return loadData(state, action);
        case STOP_LOADING_TRUCKS:
            return stopLoading(state);
        default:
            return state;
    }
}
