import React from 'react';
import {
    Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import history from './history';
import Applications from './sections/applications';
import Charts from './sections/charts';
import Dashboard from './sections/dashboard';
import Components from './sections/components';
import Pages from './sections/pages';
import Reports from './sections/reports';
import Trucks from './sections/reports/trucks';
import Auctions from './sections/reports/auctions2';
import AuctionDetail from './sections/reports/auctions/auctionDetail';
import TruckDetail from './sections/reports/truckDetail';
import Inventory from './sections/reports/inventory';
import Sales from './sections/reports/sales';
import Customers from './sections/reports/customers';
import Payments from './sections/reports/payments';
import Invoices from './sections/reports/invoices';
import Returns from './sections/reports/returns';

import Users from './sections/pages/users';
import UserDetails from './sections/pages/userDetails';
import Orders from './sections/pages/orders';
import Followers from './sections/pages/followers';
import Messages from './sections/messages';
import Forms from './sections/forms';
import ComingSoon from './sections/comingSoon';
import AuctionsPage from './sections/auctions';
import NewDashboard from './sections/newdashboard';
import QuickDashboard from './sections/quickdashboard';
import TruckSources from './sections/reports/truckSources';

export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Redirect from="/" exact to="/dashboard" />
                <Route path="/applications" component={Applications} />
                <Route path="/charts" component={Charts} />
                <Route path="/components" component={Components} />
                
                <Route path="/messages" component={Messages} />
                <Route path="/forms" component={Forms} />
                <Route path="/pages" exact component={Pages} />
                <Route path="/pages/users" component={Users} />
                <Route path="/pages/user/:uid" component={UserDetails} />
                <Route path="/pages/orders" component={Orders} />
                <Route path="/pages/followers" component={Followers} />
                <Route path="/comingSoon" component={ComingSoon} />


                <Route path="/location/:location/:start/:end" component={NewDashboard} />
                <Route path="/location/:location" component={NewDashboard} />
                <Route path="/dashboard" component={QuickDashboard} />


                <Route path="/auctions" exact component={AuctionsPage} />

                <Route path="/reports" exact component={Reports} />

                <Route path="/reports/trucksources/:start/:end" component={TruckSources} />
                <Route path="/reports/trucksources" component={TruckSources} />
                
                <Route path="/reports/trucks" component={Trucks} />
                <Route path="/reports/truck/:id/:long" component={TruckDetail} />

                <Route path="/reports/inventory/:asof" component={Inventory} />
                <Route path="/reports/inventory" component={Inventory} />
                
                <Route path="/reports/sales/:start/:end" component={Sales} />
                <Route path="/reports/sales" component={Sales} />

                <Route path="/reports/auctions" component={Auctions} />
                <Route path="/reports/auction/:id/:number" component={AuctionDetail} />

                <Route path="/reports/customers" component={Customers} />
                
                <Route path="/reports/payments/:start/:end" component={Payments} />
                <Route path="/reports/payments" component={Payments} />

                <Route path="/reports/invoices/:start/:end" component={Invoices} />
                <Route path="/reports/invoices" component={Invoices} />

                <Route path="/reports/returns/:start/:end" component={Returns} />
                <Route path="/reports/returns" component={Returns} />

    
            </Switch>
        </Router>
    );
}
