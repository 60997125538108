import React from 'react';
import PropTypes from 'prop-types';

export default function Users(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
            <g fill="none" fillRule="nonzero">
                <path fill="#E3E5ED" d="M39.3 34.265c-5.117 0-9.278-4.161-9.278-9.277 0-5.117 4.16-9.278 9.277-9.278 5.117 0 9.278 4.161 9.278 9.278 0 5.116-4.161 9.277-9.278 9.277z" />
                <path fill="#E3E5ED" d="M39.3 33.218c4.537 0 8.23-3.692 8.23-8.23 0-4.539-3.693-8.23-8.23-8.23-4.539 0-8.231 3.691-8.231 8.23 0 4.538 3.692 8.23 8.23 8.23zm0 2.094c-5.696 0-10.326-4.63-10.326-10.324 0-5.695 4.63-10.325 10.325-10.325 5.695 0 10.325 4.63 10.325 10.325 0 5.694-4.63 10.324-10.325 10.324z" />
                <path fill="#01B6F5" d="M6.822 47.315c.468-.936 1.873-2.637 2.76-3.573 1.037-1.094 3.112-2.82 4.657-3.597 1.724-.865 3.96-1.187 6.504-1.187 2.319 0 6.09.987 8.554 2.32 2.464 1.332 4.016 5.283 3.376 5.248-.355-.018-1.109 2.387-1.109 2.696 0 9.977-9.136 9.977-9.143 10.265-.911-.22-3.03-1.107-5.125-2.265-2.588-1.282-5.693-4.067-6.827-5.274-.739-.789-2.538-2.76-3.647-4.633z" />
                <path fill="#04A1D8" d="M11.233 51.231c1.578 1.68 4.488 4.042 6.57 5.074 1.784.987 3.775 1.863 4.731 2.13.997-.244 2.963-.807 3.946-1.359 2.544-1.427 4.037-3.879 4.037-7.854 0-1.055 1.004-3.805 2.213-3.741a.888.888 0 0 0-.956.749c-.006.046-.01.006-.03-.074-.075-.31-.26-.766-.518-1.247-.64-1.195-1.533-2.227-2.427-2.71-2.27-1.228-5.87-2.194-8.056-2.194-2.58 0-4.578.345-6.034 1.076-1.277.642-3.246 2.199-4.366 3.381-.818.863-1.775 2.042-2.297 2.838.823 1.268 1.948 2.61 3.187 3.931zm12.204 9.578l-1.261-.304c-1.11-.268-3.366-1.25-5.345-2.345-2.297-1.137-5.39-3.648-7.126-5.496-1.508-1.608-2.846-3.231-3.784-4.815l-.29-.492.254-.51c.473-.946 1.798-2.624 2.937-3.826 1.275-1.346 3.431-3.05 4.947-3.812 1.796-.901 4.095-1.298 6.974-1.298 2.544 0 6.484 1.057 9.052 2.445 1.31.708 2.46 2.038 3.277 3.564.336.627.587 1.243.708 1.74.256 1.055.135 1.983-1.162 1.912.473.025.559-.016.632-.14-.073.146-.176.391-.28.679-.178.486-.359 1.121-.359 1.111 0 4.747-1.913 7.89-5.106 9.68-1.176.66-3.111 1.245-4.057 1.456l-.011.451zm9.813-13.377a1.066 1.066 0 0 0 .012-.019l-.012.02z" />
                <path fill="#01B6F5" d="M22.421 59.487c-.233 0-.376-1.08-.605-1.18-.9-.386-.394-1.134-.35-2.063.633-13.707 10.213-18.446 17.642-18.446 7.428 0 13.622 4.22 16.557 11.035-3.622 4.741-8.403 8.93-16.557 11.346-11.093 2.218-15.528-.32-16.687-.692z" />
                <path fill="#04A1D8" d="M39.108 38.846c-8.902 0-16.072 6.106-16.597 17.447-.043.944.234 2.187.366 2.25.371.145 1.428.544 1.996.713 3.35.993 7.793 1.143 13.938-.081 6.891-2.042 11.62-5.393 15.64-10.487-2.88-6.147-8.589-9.842-15.343-9.842zM22.101 60.484l-.135-.054c-1.21-.584-1.63-2.46-1.547-4.234.579-12.514 8.717-19.445 18.69-19.445 7.8 0 14.399 4.425 17.518 11.668l.242.563-.372.487c-4.362 5.71-9.536 9.476-17.184 11.737-6.563 1.312-11.35 1.15-15.035.058-.817-.242-2.326-.828-2.177-.78z" />
                <path fill="#E3E5ED" d="M20.744 35.91a6.967 6.967 0 0 1-6.958-6.958 6.967 6.967 0 0 1 6.958-6.958 6.967 6.967 0 0 1 6.958 6.958 6.967 6.967 0 0 1-6.958 6.958z" />
                <path fill="#E3E5ED" d="M20.744 34.863a5.92 5.92 0 0 0 5.91-5.91 5.92 5.92 0 0 0-5.91-5.912 5.92 5.92 0 0 0-5.91 5.911 5.92 5.92 0 0 0 5.91 5.91zm0 2.095c-4.414 0-8.005-3.591-8.005-8.006 0-4.414 3.59-8.005 8.005-8.005 4.415 0 8.006 3.59 8.006 8.005 0 4.415-3.591 8.006-8.006 8.006z" />
                <path fill="#04A1D8" d="M31.761 59.735c15.638 0 28.315-12.677 28.315-28.315 0-15.638-12.677-28.315-28.315-28.315-15.638 0-28.315 12.677-28.315 28.315 0 15.638 12.677 28.315 28.315 28.315zm0 2.514C14.735 62.249.932 48.446.932 31.42S14.735.591 31.762.591c17.025 0 30.828 13.803 30.828 30.83 0 17.025-13.803 30.828-30.829 30.828z" />
            </g>
        </svg>
    );
}

Users.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Users.defaultProps = {
    className: undefined,
    style: undefined,
};
