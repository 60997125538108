import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-rainbow-components';
import ChartsSection from './chartsSection';
import InvoicesTabs from './invoicesTabs';


export default function ContentPage(props) {
    
    const {
        invoices,
        chartsData,
        isLoading,
        updateData
    } = props;

    if (isLoading) {
        return <Spinner size="large" />;
    }
    return (
        <div>
            <ChartsSection invoices={invoices} chartsData={chartsData} updateData={updateData} />
            <InvoicesTabs invoices={invoices} />
        </div>
    );
}

ContentPage.propTypes = {
    invoices: PropTypes.array.isRequired,
    chartsData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
