import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Badge from 'react-rainbow-components/components/Badge';
import Dataset from 'react-rainbow-components/components/Dataset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

export default class ChartsSection extends PureComponent {
    render() {
        const {
            chartsData,
        } = this.props;

        console.log('CHARTS SECTION!!', this.props);
        return (
            <div>
            <section className="react-rainbow-admin-orders_cards-container">
                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.scans.title}
                    actions={(
                        <Badge variant="brand" className="react-rainbow-admin-users_badge">
                            {chartsData.scans.badge}
                        </Badge>
                    )}
                    >
                    <h1 className="react-rainbow-admin-users_card-title">Scans by Day</h1>
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.scans.labels}
                            type="line"
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset values={chartsData.scans.value} borderColor="#1de9b6" backgroundColor="#1de9b6" />
                        </Chart>
                    </div>
                </Card>

                <Card
                    className="react-rainbow-admin-users_card"
                    title={chartsData.sales.title}
                    actions={(
                        <Badge variant="brand" className="react-rainbow-admin-users_badge">
                            {chartsData.sales.badge}
                        </Badge>
                    )}>
                    <h1 className="react-rainbow-admin-users_card-title">Sales by Day</h1>
                    <div className="react-rainbow-admin-users_chart">
                        <Chart
                            labels={chartsData.sales.labels}
                            type="bar"
                            showStacked={true}
                            showLegend={false}
                            maintainAspectRatio={false}>
                            <Dataset title="AUCTION" values={chartsData.sales.auction} borderColor="#fe4849" backgroundColor="#fe4849" />
                        </Chart>
                    </div>
                </Card>
            </section>
            
            </div>
        );
    }
}

ChartsSection.propTypes = {
    chartsData: PropTypes.object.isRequired,
};
