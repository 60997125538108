import React from 'react';
import PropTypes from 'prop-types';

export default function Microphone(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} width="19px" height="26px" viewBox="0 0 19 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-8" transform="translate(-975.000000, -564.000000)" fillRule="nonzero">
                    <g id="microphone" transform="translate(975.000000, 564.000000)">
                        <path d="M18.5840956,12.3948526 C18.5840956,11.9656972 18.236255,11.6179602 17.8072032,11.6179602 C17.3781514,11.6179602 17.0303108,11.9656972 17.0303108,12.3948526 C17.0303108,16.6393785 13.5770757,20.0926135 9.3325498,20.0926135 C5.0880239,20.0926135 1.63478884,16.6392749 1.63478884,12.3948526 C1.63478884,11.9656972 1.28694821,11.6179602 0.857896414,11.6179602 C0.428844622,11.6179602 0.0810039841,11.9656972 0.0810039841,12.3948526 C0.0810039841,17.2345817 3.81650996,21.2175538 8.55565737,21.6134582 L8.55565737,24.3622072 L5.09517131,24.3622072 C4.66611952,24.3622072 4.31827888,24.7099442 4.31827888,25.1390996 C4.31827888,25.568255 4.66611952,25.915992 5.09517131,25.915992 L13.5698247,25.915992 C13.9988765,25.915992 14.3467171,25.568255 14.3467171,25.1390996 C14.3467171,24.7099442 13.9988765,24.3622072 13.5698247,24.3622072 L10.1093386,24.3622072 L10.1093386,21.6134582 C14.8484861,21.2175538 18.5840956,17.2345817 18.5840956,12.3948526 Z" id="Shape" fill="#576574" />
                        <path d="M9.33244622,17.4090199 C12.0973546,17.4090199 14.3467171,15.1596574 14.3467171,12.3948526 L14.3467171,5.01416733 C14.3467171,2.24936255 12.0973546,0 9.33244622,0 C6.56764143,0 4.31827888,2.24936255 4.31827888,5.01416733 L4.31827888,12.3948526 C4.31827888,15.159761 6.56764143,17.4090199 9.33244622,17.4090199 Z" id="Shape" fill="#01B6F5" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Microphone.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Microphone.defaultProps = {
    className: undefined,
    style: undefined,
};
