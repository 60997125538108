import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Badge from 'react-rainbow-components/components/Badge';
import Dataset from 'react-rainbow-components/components/Dataset';
import Tile from '../../../../components/Tile';

export default class ChartsSection extends PureComponent {

    constructor(props) {
        console.log('AUCTION DETAIL CHART PROPS', props);
        super(props);
        this.state = {
            lots: props.lots,
            total_revenue: props.lots.reduce((pv, cv) => pv + (cv.sale_price * 1), 0),
            total_cogs: props.lots.reduce((pv, cv) => pv + (cv.cost * 1), 0),
            total_retail: props.lots.reduce((pv, cv) => pv + (cv.retail_price * 1), 0),
            total_bids: props.lots.reduce((pv, cv) => pv + (cv.total_bids * 1), 0)
        };

    }
    render() {
        return (
            <>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                        title="Auction Lots"
                        label={this.state.lots.length}
                        />
                <Tile
                        title="Auction Revenue"
                        label={'$' + this.state.total_revenue.toFixed(2)}
                        />
                <Tile
                        title="Auction COGS"
                        label={'$' + this.state.total_cogs.toFixed(2)}
                        />
                <Tile
                        title="ROI"
                        label={ ((this.state.total_revenue - this.state.total_cogs) / this.state.total_cogs * 100).toFixed(2) + '%' }
                        />
                
            
            </section>
            <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                        title="Auction Retail"
                        label={'$' + this.state.total_retail.toFixed(2)}
                        />
                <Tile
                        title="Recovery Rate"
                        label={(this.state.total_revenue / this.state.total_retail * 100).toFixed(2) + '%'}
                        />
                
                <Tile
                        title="Total Bids"
                        label={this.state.total_bids}
                        />
                <Tile
                        title="Bids / Lot"
                        label={ (this.state.total_bids / this.state.lots.length).toFixed(2) }
                        />
                
            
            </section>
            </>
        );
    }
}

ChartsSection.propTypes = {
    lots: PropTypes.array.isRequired,
};
