import React from 'react';
import PropTypes from 'prop-types';

export default function Attach(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} width="20px" height="24px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-8" transform="translate(-591.000000, -564.000000)">
                    <g id="attach" transform="translate(591.000000, 564.000000)">
                        <polygon id="Path" fill="#FFCC00" points="0.0314136126 3.21302356 15.1965805 3.21302356 15.1965805 23.9685864 0.0314136126 23.9685864" />
                        <polygon id="Path" fill="#E3E5ED" points="1.68995422 5.054892 16.6322808 2.46155108 19.3610929 18.1850458 4.41876635 20.7783868" />
                        <path d="M12.5418848,0.0314136126 C10.6170648,0.0314136126 9.00613546,1.39888743 8.62819043,3.21302356 L9.97161324,3.21302356 C10.3183901,2.12385474 11.3393325,1.33237891 12.5418848,1.33237891 C14.0288776,1.33237891 15.2387925,2.54229386 15.2387925,4.02928662 L15.2387925,11.7320026 C15.2387925,12.6407886 14.4993455,13.380481 13.5903141,13.380481 C12.6815281,13.380481 11.9418357,12.6407886 11.9418357,11.7320026 L11.9418357,4.40600457 C11.9418357,4.09579514 12.1941263,3.84375003 12.5040903,3.84375003 C12.8140543,3.84375003 13.0660995,4.09604054 13.0660995,4.40600457 L13.0660995,10.6180465 L14.3670648,10.6180465 L14.3670648,4.40600457 C14.3670648,3.3786813 13.5314136,2.54278467 12.5040903,2.54278467 C11.476767,2.54278467 10.6408705,3.3786813 10.6408705,4.40600457 L10.6408705,11.7320026 C10.6408705,13.3581479 11.9641689,14.6814464 13.5903141,14.6814464 C15.2167049,14.6814464 16.5397578,13.3581479 16.5397578,11.7320026 L16.5397578,4.02928662 C16.5397578,1.82468915 14.7464823,0.0314136126 12.5418848,0.0314136126 Z" id="Path" fill="#5C56B6" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Attach.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Attach.defaultProps = {
    className: undefined,
    style: undefined,
};
