import {
    STOP_LOADING_CUSTOMERS,
    LOAD_CUSTOMERS_DATA,
    START_LOADING_CUSTOMERS,
} from '../../actions/customers';

const initialState = {
    customers: [],
    chartsData: {
        totalCustomers: {
            labels: [],
            value: []
        },
        newCustomers: {
            labels: [],
            value: []
        }
    },
    isLoading: true,
    isFirstTime: true,
};

function startLoading(state) {
    return {
        ...state,
        isLoading: true,
    };
}

function loadData(state, action) {
    const newState = {
        ...state,
        chartsData: {
            newCustomers: {
                ...action.chartsData.acquisition
            },
            totalCustomers: {
                labels: action.chartsData.acquisition.labels,
                value: action.chartsData.acquisition.value.map( (sum => v => sum += v)(0) )
            },
            activeCustomers: {
                ...action.chartsData.active
            }
        },
        isFirstTime: false,
    };
    console.log('load data reducer', newState);
    return newState;
}

function stopLoading(state) {
    return {
        ...state,
        isLoading: false,
    };
}

export default function customers(state = initialState, action) {
    switch (action.type) {
        case START_LOADING_CUSTOMERS:
            return startLoading(state);
        case LOAD_CUSTOMERS_DATA:
            return loadData(state, action);
        case STOP_LOADING_CUSTOMERS:
            return stopLoading(state);
        default:
            return state;
    }
}
