const APIHOST = (process.env.NODE_ENV === "development1") ? "http://localhost:9006/api" : "https://recommerce-pist2.herokuapp.com/api";


function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}

export function getKpis(start=null, end=null, location=null) {
    console.log('GETTING KPIS...');
    let url = APIHOST + "/kpis";
    if(start && end) {
        url += "/" + start + "/" + end + "/" + location;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getHourlyScans(hours=12) {
    console.log('GETTING HOURLY SCANS...');
    let url = APIHOST + "/hourlyScans";
    if(hours) {
        url += "/" + hours;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getDailyScans(start=null, end=null) {
    console.log('GETTING DAILY SCANS...');
    let url = APIHOST + "/dailyScans";
    if(start && end) {
        url += "/" + start + "/" + end;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getHourlyBids(hours=12) {
    console.log('GETTING HOURLY Bids...');
    let url = APIHOST + "/hourlyBids";
    if(hours) {
        url += "/" + hours;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getDailyBids(start=null, end=null) {
    console.log('GETTING DAILY Bids...');
    let url = APIHOST + "/dailyBids";
    if(start && end) {
        url += "/" + start + "/" + end;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getDailyRevenue(start=null, end=null) {
    console.log('GETTING DAILY REVENUE...');
    let url = APIHOST + "/dailyRevenue";
    if(start && end) {
        url += "/" + start + "/" + end;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getDailyRetailValue(start=null, end=null) {
    console.log('GETTING DAILY RETAIL VLUE...');
    let url = APIHOST + "/dailyRetailValue";
    if(start && end) {
        url += "/" + start + "/" + end;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}

export function getDailyNewUsers(start=null, end=null) {
    console.log('GETTING DAILY NEW USERS...');
    let url = APIHOST + "/dailyNewUsers";
    if(start && end) {
        url += "/" + start + "/" + end;
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(handleErrors)
    .then(res => {
        return res.json();
    }).catch( err => {
        console.log('MY ERR', err);
        return err;
    });
    
}